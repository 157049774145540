import { Fragment, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsList, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { CountryDropdown } from "react-country-region-selector";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { REDUX_ACTIONS } from "../Redux/Actions/DataObjectAction";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";


const MainContainer_Header = (props) => {
  const navigate = useNavigate();
  const [selectedBtn, setSelected] = useState(null);
  const [subToggle, setSubToggle] = useState(false);
  const [showLanguageModel, setShowLanguageModel] = useState(false);
  const [country, setCountry] = useState(JSON.parse(localStorage.getItem("country")));
  const [search, setSearch] = useState("");

  const [areaData, setAreaData] = useState(null);
  const [areaDataStore, setAreaDataStore] = useState(null);
  const [areaname, setAreaName] = useState("");

  const [outletSellerData, setOutletSellerData] = useState(null);
  const [sellerDataStore, setSellerDataStore] = useState(null);
  const [sellerName, setSellerName] = useState("");

  const [offerTagData, setOfferTagData] = useState(null);
  const [tagDataStore, setTagDataStore] = useState(null);
  const [tagName, setTagName] = useState("");


  const [tagClick, setTagClick] = useState([]);
  const [offerData, setOfferData] = useState();

  //map Area data getCategory
  const ShowAreaData = (e) => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    axios
      .post("/Product/getCategory")
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        setAreaName("");
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setAreaData(res.data.data);
          setAreaDataStore(res.data.data);
          // toast.success(ResponseMsg);
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //area filter
  const areaFilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = areaDataStore.filter((user) => {
        return user.cat_name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setAreaData(results);
    } else {
      setAreaData(areaDataStore);
    }
    setAreaName(keyword);
  };

  //map seller data getOutlet
  const outletData = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    axios
      .post("/Offer/getOutlet")
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        setSellerName("");

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setOutletSellerData(res.data.data);
          setSellerDataStore(res.data.data);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //sellerFilter
  const sellerFilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = sellerDataStore.filter((user) => {
        return user.outlet_name
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      });
      setOutletSellerData(results);
    } else {
      setOutletSellerData(sellerDataStore);
    }
    setSellerName(keyword);
  };

  //map tag getSpecialOffer
  const specialOffer = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("radius", 50);

    axios
      .post("/Offer/getSpecialOffer", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        setTagName("");

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");

          setOfferTagData(res.data.data.tags);
          setTagDataStore(res.data.data.tags);
          setOfferData(res.data.data.offers);

        } else {
          // toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        $("#loadSpin").attr("hidden", "hidden");
        $(".map ").css("opacity", "1");
        console.log(err);
      });
  };

  //tagFilter
  const tagFilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = tagDataStore.filter((user) => {
        return user.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setOfferTagData(results);
    } else {
      setOfferTagData(tagDataStore);
    }

    setTagName(keyword);
  };


  //setprofile
  var user = JSON.parse(localStorage.getItem("user"));
  var uid = localStorage.getItem("userId");

  const dispatch = useDispatch();
  const handleClose = () => setShowLanguageModel(false);
  const handleShow = () => setShowLanguageModel(true);

  let formData = new FormData();
  var uid = localStorage.getItem("userId");
  formData.append("user_id", uid);

  const logOut = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar ").css("opacity", ".1");
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/logOut",
    };

    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar ").css("opacity", "1");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("profile");
          localStorage.clear();
          navigate("/");
          window.location.reload();
          // toast.success(ResponseMsg);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar ").css("opacity", ".1");
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = [
    { value: "DK", label: "Denmark" },
    { value: "US", label: "United States" },
  ];

  const changeCountry = (e) => {
    setCountry(e);
    localStorage.setItem("country", JSON.stringify(e));
  };

  //for demo
  // const searchData = (e) => {
  //   e.preventDefault();
  //   var value = e.target.value;
  //   setSearch(value)
  //   if (value == '' || value == null) {
  //     window.location.reload()
  //   } else {
  //     dispatch({
  //       type: REDUX_ACTIONS.SEARCH,
  //       payload: { search_name: search.trim() !== "" ? search : "", hash: "#/" }
  //     });
  //   }
  // };
  const searchData = (e) => {
    e.preventDefault();
      dispatch({
      type: REDUX_ACTIONS.SEARCH,
      payload: { search_name: search.trim() !== "" ? search : "", hash: "#/" }
    });
  };

  const searchDataHome = (e) => {
    e.preventDefault();
    navigate('/offer')
      dispatch({
        type: REDUX_ACTIONS.SEARCH,
        payload: { search_name: search.trim() !== "" ? search : "", hash: "#/" }
      });
  };
  const searchTag = (product_tag) => {
    const currentIndex = tagClick.indexOf(product_tag);
    const newClick = [...tagClick]
    if (currentIndex === -1) {
      newClick.push(product_tag)  
    } else {
      newClick.splice(currentIndex, 1)
    }
    setTagClick(newClick)
    props.handleTagFilter(newClick)
  };
  return (
    <Fragment>
      <div
        id="loadSpin"
        className="spinner-border"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="navbar">
        <div className="d-flex align-items-center justify-content-between w-100">
          <Button
            className="toggle-sidebar"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              props.setToggle(!props.toggle);
            }}
          >
            <BsList />
          </Button>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="shoping-def">
              <div className="d-flex justify-content-around">
                <div
                  className="d-flex align-items-center flex-column"
                  onClick={() => {
                    localStorage.getItem("token") == null || localStorage.getItem("token") == ''
                      ? navigate("/login")  
                      : navigate("/offers") ? navigate("/offers") : window.location.reload();
                  }}
                >
                  <img
                    src={require("../assets/img/offers.png").default}
                    alt=""
                  />
                  <h6>OFFERS</h6>
                </div>
                <div
                  className="d-flex align-items-center flex-column"
                  onClick={() => {
                    localStorage.getItem("token") == null || localStorage.getItem("token") == ''
                      ? navigate("/login")
                      : navigate("/deal") 
                  }}
                >
                  <img
                    src={require("../assets/img/deals.png").default}
                    alt=""
                  />
                  <h6>DEALS</h6>
                </div>
                <div
                  className="d-flex align-items-center flex-column"
                  onClick={() => navigate("/map")}>
                
                  <img
                    src={require("../assets/img/on_map.png").default}
                    alt=""
                  />
                  <h6>ON MAP</h6>
                </div>
                <div
                  className="d-flex align-items-center flex-column"
                  onClick={() => {
                    localStorage.getItem("token")
                      ? navigate("/cart")
                      : navigate("/login");
                  }}
                >
                  <img src={require("../assets/img/cart.png").default} alt="" />
                  <h6>CART</h6>
                </div>
              </div>
              <div className="shoping-btns d-flex position-relative">
                {/* <!-- Button Area modal --> */}
                <Button
                  className="area px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#area"
                  onClick={ShowAreaData}
                >
                  AREA
                </Button>

                {/* <!-- Area Modal --> */}
                <div
                  className="modal fade"
                  id="area"
                  tabindex="-1"
                  aria-labelledby="areaLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="areaLabel">
                          Choose Area
                        </h5>
                        <form>
                          <input
                            type="text"
                            value={areaname}
                            onChange={areaFilter}
                            placeholder="Search..."
                          />
                        </form>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                      </div>

                      <hr />

                      <div className="modal-body">
                        {areaData && areaData.length > 0 ? (
                          areaData.map((user) => (
                            <>
                              <div className="area-user">
                                
                                <p
                                  key={user.id}
                                  className="user-name"
                                >
                                  <input type="checkbox"
                                    onChange={() => props.cat_id(user.cat_id)} />
                                  {user.cat_name}
                                </p>
                              </div>
                              <hr />
                            </>
                          ))
                        ) : (
                          <>
                            <h1>No data found!</h1>
                            {/* <img src="https://i.stack.imgur.com/181Qp.gif" alt="" /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Button Seller modal --> */}
                <Button
                  className="pink-btn px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#seller"
                  onClick={outletData}
                >
                  SELLER
                </Button>

                {/* <!-- Seller Modal --> */}
                <div
                  className="modal fade"
                  id="seller"
                  tabindex="-1"
                  aria-labelledby="sellerLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog seller-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="sellerLabel">
                          Choose Seller
                        </h5>
                        <form>
                          <input
                            type="text"
                            value={sellerName}
                            onChange={sellerFilter}
                            placeholder="Search..."
                            className="seller-search"
                          />
                        </form>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                      </div>
                      <div className="modal-body">
                        {outletSellerData && outletSellerData.length > 0 ? (
                          outletSellerData.map((item, i) => (
                            <div
                              key={i}
                              className="seller_content"
                            >
                              <input type="checkbox"
                                  onChange={() => props.seller_outletId(item.outlet_id)} />
                              <div className="seller_imag">
                                <img
                                  src={
                                    item.logo == "" || item.logo == null
                                      ? require("../assets/img/InstaSale.png").default
                                      : item.logo
                                  }
                                  alt=""
                                />
                              </div>

                              <div className="content_detail">
                                <h6>{item.outlet_name}</h6>
                                <p className="address" title={item.address}>
                                  Address: {item.address}
                                </p>

                                <p className="address">Zip/code: {item.zip}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <>
                            <h1>No data found!</h1>
                            {/* <img src="https://i.stack.imgur.com/181Qp.gif" alt="" /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Button Tags modal --> */}
                <Button
                  className="yellow-btn px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#tags"
                  onClick={specialOffer}
                >
                  TAGS
                </Button>

                {/* <!-- tags Modal --> */}
                <div
                  className="modal fade"
                  id="tags"
                  tabindex="-1"
                  aria-labelledby="tagsLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog tag-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="tagsLabel">
                          Choose Tags
                        </h5>
                        <form>
                          <input
                            type="text"
                            value={tagName}
                            onChange={tagFilter}
                            placeholder="Search..."
                          />
                        </form>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                      </div>

                      <div className="modal-body">
                        {offerTagData && offerTagData.length > 0 ? (
                          offerTagData.map((item, i) =>(
                              <div className="btnTag position-relative" key={i}>
                              <input type="checkbox"
                                checked={tagClick.indexOf(item.toString()) === -1 ? false : true}
                                onChange={() => searchTag(item.toString())}
                              />
                                <label >
                                  <div className=" tag-select">{item.toString()}</div>
                                </label>
                              </div>
                              
                              
                            )
                          )
                        ) : (
                            <h1>No Data Found</h1>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="logo">
              <img
                src={require("../assets/img/logo.png").default}
                alt=""
                onClick={() => navigate("/")}
              />
            </div>
            <div className="user d-flex">
              {/* <img className="user_img" src={require('../assets/img/user.png').default} alt="" onClick={() => navigate("/")} /> */}
              {localStorage.getItem("token") ? (
                <>
                  {user && user.profile && user.profile != "" ? (
                    <img
                      onClick={() => navigate("/profile")}
                      className="user_img"
                      src={user.profile}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require("../assets/img/ic_profile.png").default;
                    }}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => navigate("/profile")}
                      className="user_img"
                      src={require("../assets/img/ic_profile.png").default}
                      alt=""
                    />
                  )}
                </>
              ) : (
                ""
              )}

              <div className="user-def">
                <div className="d-flex align-items-center">
                  {localStorage.getItem("token") ? (
                    <>
                      <Button
                        onClick={() =>
                          window.open(
                            "https://instasale.app/Instasale/Admin/login"
                          )
                        }>

                        <img
                          src={require("../assets/img/create.png").default}
                          alt=""
                        />
                        <span>CREATE</span>
                      </Button>
                      <Button onClick={logOut}>
                        <img
                          src={require("../assets/img/login.png").default}
                          alt=""
                        />
                        <span>LOGOUT</span>
                      </Button>
                      <Button>
                        {/* <img className="language mb-4" src={require('../assets/img/language.png').default} alt="" onClick={() => navigate("/")} /> */}
                        <ReactCountryFlag
                          countryCode={country.value}
                          svg
                          style={{
                            width: "25px",
                            height: "25px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          className="mb-3"
                          onClick={handleShow}
                        />

                        <Modal
                          className='country-select'
                          show={showLanguageModel}
                          onHide={handleClose}
                          backdrop="static"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Select Country</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Select
                              options={options}
                              value={country}
                              onChange={changeCountry}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="submit" onClick={() => navigate("/login")}>
                        <img
                          src={require("../assets/img/login.png").default}
                          alt=""
                        />
                        <span>LOGIN</span>
                      </Button>
                      <Button type="submit" onClick={() => navigate("/signup")}>
                        <img
                          src={require("../assets/img/signup.png").default}
                          alt=""
                        />

                        <span>SIGNUP</span>
                      </Button>
                    </>
                  )}
                </div>
                
                {(window.location.hash === "#/offers" || window.location.hash === "#/deal" || window.location.hash === "#/rewards") && (
                  <form onSubmit={searchData}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="search_bar"
                        value={search}
                      onChange={(e) => setSearch(e.target.value) }
                      />
                    </form>
                  )}
                {( window.location.hash === "#/") && (
                  <form onSubmit={searchDataHome}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="search_bar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </form>
                  )}
              </div>
            </div>
            <div
              className="right-toggle d-flex"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setSubToggle(!subToggle);
              }}
            >
              <BsThreeDotsVertical />
              <div className={`${!subToggle ? "d-none" : "sub-box"} sub-box`}>
                <div className="shoping-def-sub">
                  <div className="shoping-img d-flex justify-content-around">
                    <div className="shoping d-flex justify-content-around">
                      <div
                        className="d-flex align-items-center flex-column"
                        onClick={() => {
                          localStorage.getItem("token") == null || localStorage.getItem("token") == ''
                            ? navigate("/login")
                            : navigate("/offers") ? navigate("/offers") : window.location.reload();
                        }}
                      >
                        <img
                          src={require("../assets/img/offers.png").default}
                          alt=""
                        />
                        <h6>OFFERS</h6>
                      </div>
                      <div
                        className="d-flex align-items-center flex-column"
                        // onClick={() => navigate("/")}
                        onClick={() => {
                          localStorage.getItem("token") == null || localStorage.getItem("token") == ''
                            ? navigate("/login")
                            : navigate("/deal")
                            // : navigate("/deal") ? navigate("/") : window.location.reload();
                        }}
                      >
                        <img
                          src={require("../assets/img/deals.png").default}
                          alt=""
                        />

                        <h6>DEALS</h6>
                      </div>
                      <div
                        className="d-flex align-items-center flex-column"
                        onClick={() => navigate("/map")}
                      >
                        <img
                          src={require("../assets/img/on_map.png").default}
                          alt=""
                        />
                        <h6>ON MAP</h6>
                      </div>
                      <div
                        className="d-flex align-items-center flex-column"
                        onClick={() => {
                          localStorage.getItem("token")
                            ? navigate("/cart")
                            : navigate("/login");
                        }}
                      >
                        <img
                          src={require("../assets/img/cart.png").default}
                          alt=""
                        />
                        <h6>CART</h6>
                      </div>
                    </div>
                  </div>
                  <div className="shoping-btns d-flex mt-2">
                    {/* <!-- Button Area modal --> */}
                    <Button
                      className="area px-4 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#m-area"
                      onClick={ShowAreaData}
                    >
                      AREA
                    </Button>

                    {/* <!-- Button Seller modal --> */}
                    <Button
                      className="pink-btn px-4 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#m-seller"
                      onClick={outletData}
                    >
                      SELLER
                    </Button>

                    {/* <!-- Button Tags modal --> */}
                    <Button
                      className="yellow-btn px-4"
                      data-bs-toggle="modal"
                      data-bs-target="#m-tags"
                      onClick={specialOffer}
                    >
                      TAGS
                    </Button>
                  </div>
                  <div className="sub-menu-btn d-flex align-items-center">

                    {localStorage.getItem("token") ? (
                      <>
                        <Button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/profile");
                          }}
                        >
                          <img
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                            }}
                            src={
                              localStorage.getItem("profile")
                                ? localStorage.getItem("profile")
                                : require("../assets/img/ic_profile.png")
                                  .default
                            }
                            alt="profile"
                            onClick={() => navigate("/profile")}
                          />
                          <span>PROFILE</span>
                        </Button>
                        <Button type="submit" onClick={logOut}>
                          <img
                            src={require("../assets/img/login.png").default}
                            alt=""
                          />
                          <span>LOGOUT</span>
                        </Button>
                        <Button>
                          {/* <img className="language mb-4" src={require('../assets/img/language.png').default} alt="" onClick={() => navigate("/")} /> */}
                          <ReactCountryFlag
                            countryCode={country.value}
                            svg
                            style={{
                              width: "25px",
                              height: "25px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            className="mb-3"
                            onClick={handleShow}
                          />

                          <Modal
                            className='country-select'
                            show={showLanguageModel}
                            onHide={handleClose}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Select Country</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Select
                                options={options}
                                value={country}
                                onChange={changeCountry}
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          onClick={() => navigate("/login")}
                        >
                          <img
                            src={require("../assets/img/login.png").default}
                            alt=""
                          />
                          <span>LOGIN</span>
                        </Button>
                        <Button
                          type="submit"
                          onClick={() => navigate("/signup")}
                        >
                          <img
                            src={require("../assets/img/signup.png").default}
                            alt=""
                          />
                          <span>SIGNUP</span>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {(window.location.hash === "#/offers" || window.location.hash === "#/deal" || window.location.hash === "#/rewards") && (
          <div className="phone-search px-3 pb-2">
          <form onSubmit={searchData}>
            <input
              type="text"
              placeholder="Search..."
              className="search_bar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            </form>
            </div>
        )}
        {(window.location.hash === "#/") && (
          <div className="phone-search px-3 pb-2">
          <form onSubmit={searchDataHome}>
            <input
              type="text"
              placeholder="Search..."
              className="search_bar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            </form>
            </div>
        )}
      </div>

      {/* <!-- m-area Modal --> */}
      <div className="shoping-btns">
        <div
          className="modal fade"
          id="m-area"
          tabindex="-1"
          aria-labelledby="areaLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog area-mobile-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="areaLabel">
                  Choose Area
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <form>
                  <input
                    type="text"
                    value={areaname}
                    onChange={areaFilter}
                    placeholder="Search..."
                  />
                </form>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>

              <hr />
              <div className="modal-body ">
                {areaData && areaData.length > 0 ? (
                  areaData.map((user) => (
                    <>
                      <div className="area-user">
                        <p
                          key={user.id}
                          className="user-name"
                        >
                          <input type="checkbox" onChange={() => props.cat_id(user.cat_id)}/>
                          {user.cat_name}
                        </p>
                      </div>
                      
                      <hr />
                    </>
                  ))
                ) : (
                  <h1>No data found!</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- m-Seller Modal --> */}
      <div className="shoping-btns seller-mobile-modal">
        <div
          className="modal fade"
          id="m-seller"
          tabindex="-1"
          aria-labelledby="sellerLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog seller-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="sellerLabel">
                  Choose Seller
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <form>
                  <input
                    type="text"
                    placeholder="Search..."
                    className="seller-search"
                    value={sellerName}
                    onChange={sellerFilter}
                  />
                </form>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                {outletSellerData && outletSellerData.length > 0 ? (
                  outletSellerData.map((item, i) => (
                    <div
                      key={i}
                      className="seller_content"
                    >
                      <input type="checkbox"
                        onChange={() => props.seller_outletId(item.outlet_id)} />
                      <div className="seller_imag">
                        <img src={item.logo} alt="" />
                      </div>
                      <div className="content_detail">
                        <h6>{item.outlet_name}</h6>
                        <p className="address" title={item.address}>
                          Address: {item.address}
                        </p>

                        <p className="address">Zip/code: {item.zip}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1>No data found!</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- m-tags Modal --> */}
      <div className="shoping-btns tag-mobile-modal">
        <div
          className="modal fade"
          id="m-tags"
          tabindex="-1"
          aria-labelledby="tagsLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog tag-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="tagsLabel">
                  Choose Tags
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <form>
                  <input
                    type="text"
                    value={tagName}
                    onChange={tagFilter}
                    placeholder="Search..." />

                </form>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>

              <div className="modal-body">
                {offerTagData && offerTagData.length > 0 ? (
                  offerTagData.map((item, i) => (
                    <div className="btnTag position-relative"
                      key={i}>
                      <input type="checkbox"
                        checked={tagClick.indexOf(item.toString()) === -1 ? false : true}
                        onChange={() =>
                          localStorage.getItem("token")
                            ? navigate("/offers", searchTag(item.toString()))
                            : navigate("/login")
                        }
                      />
                      <label >
                        <div className="tag-select">{item.toString()}</div>
                      </label>
                    </div>
                  ))
                ) : (
                  <h1>No data found!</h1>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};;

export default MainContainer_Header;
