import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import Header from "./Header";

export default function ProductsDetail() {
  const navigate = useNavigate();

  const { dataObject } = useSelector((state) => state);
  const { id } = useParams();
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("product_id", id);
    formData.append("lat", 21);
    formData.append("lng", 72);

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Product/getProductDetail",
    };  
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          setProductData(res.data.data);
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addTocart = () => {
    if (!localStorage.getItem("token")) {
      toast.error("please login first");
      navigate("/login");
      return;
    }
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("price", productData.normal_price);
    formData.append("outlet_id", productData.outlet_id);
    formData.append("product_id", id);

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/addCart",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          navigate("/cart");
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  var settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  return (
    <Header>
      <div
        className={`${
          !dataObject.toggle ? "product-detail-mini" : "product-detail"
        } p-3`}
      >
        <div className="offers-header text-center">
          <h1>
            <span className="pink">INSTA</span>
            <span className="orange">SALE -</span> instant offers, right where
            you are...
          </h1>
        </div>

        <Row className="mx-0 mt-4 mt-sm-5 justify-content-center">
          <Col lg={6}>
            <div>
              <Slider {...settings}>
                {productData.images &&
                  productData.images.map((val, i) => (
                    <div className="slider-img" key={i}>
                      <img
                        src={val.img}
                        alt=""
                        className="img-food img-fluid"
                      />
                    </div>
                  ))}
              </Slider>

              <div className="d-flex justify-content-between align-items-center iconer">
                <div className="d-flex justify-content-between align-items-center ">
                  <img
                    className="me-2 sm-me-4 img-fluid svgicon"
                    src={require("../assets/img/log4.svg").default}
                    alt=""
                  />  
                  <img
                    src={require("../assets/img/log3.svg").default}
                    alt=""
                    className="img-fluid svgicon1"
                  />
                </div>

                <div className="d-flex justify-content-between align-items-center ">
                  <img
                    className="me-2 sm-me-4 img-fluid svgicon"
                    src={require("../assets/img/log2.svg").default}
                    alt=""
                  />
                  <img
                    src={require("../assets/img/log1.svg").default}
                    alt=""
                    className="img-fluid svgicon"
                  />
                </div>
              </div>
              
            </div>
          </Col>

          <Col lg={6}>
            <div className="whopp ">
              <h1 className="wooph1">{productData.outletname}</h1>

              <div>
                
                <div className="d-flex align-items-center mb-2">
                  <img
                    src={
                      productData.txt_line1 &&
                      require("../assets/img/right.png").default
                    }
                    alt=""
                    className="me-2 img-fluid "
                  />
                  <h5 className="m-0">{productData.txt_line1}</h5>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <img
                    src={
                      productData.txt_line2 &&
                      require("../assets/img/right.png").default
                    }
                    alt=""
                    className="me-2 img-fluid"
                  />
                  <h5 className="m-0">{productData.txt_line2}</h5>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <img
                    src={
                      productData.txt_line3 &&
                      require("../assets/img/right.png").default
                    }
                    alt=""
                    className="me-2"
                  />
                  <h5 className="m-0 img-fluid">{productData.txt_line3}</h5>
                </div>

                <div className="pt-2">
                  <p className="p-text">{productData.description}</p>
                </div>

                <div className="d-flex justify-content-between mb-4 npm-main pt-4">
                  <div className="npm">
                    <p className="m-0">NORMAL PRICE</p>
                    <h1 className="m-0">{productData.normal_price}</h1>
                    <p className="m-0">DKKINCL.VAT</p>
                  </div>

                  <div className="npm2">
                    <p className="m-0">CIRQUS PRICE</p>
                    <h1 className="m-0">{productData.special_offer_price}</h1>
                  </div>
                </div>

                <button
                  type="submit"
                  onClick={addTocart}
                  className="cart-btn shadow-none ">
                  Add To Cart
                </button>
              </div>
            </div>
          </Col>
        </Row>
        
        <div className="p_container">
          <p className="product_text ms-xxl-5 ms-1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla consequatur quibusdam dignissimos maiores dicta cum rerum nisi maxime doloremque? Nesciunt! Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis ratione magni assumenda doloremque voluptates, rem officiis quia repudiandae quod. Expedita laborum nisi ab saepe, vitae ex optio. Quisquam, officiis delectus?</p>
        </div>
       

      </div>

      
    </Header>
  );
}
