import MainContainer from "./MainContainer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { BsApple } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import catalog from "../assets/img/catalog.png";
import blank_catalog from "../assets/img/blank_catalog.png";

const BonusReword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { dataObject } = useSelector((state) => state);
  // const [popularOffer, setPopularOffer] = useState();
  const [favoriteProduct, setfavoriteProduct] = useState();
  const [cartImg, setcartImg] = useState();
  const [cartTotalPrice, setcartTotalPrice] = useState();
  const [productData, setProductData] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [popularOffer, setPopularOffer] = useState([]);
  const [tagClick, setTagClick] = useState([]);

  // read more
  //   const ReadMore = ({ children }) => {
  //     const text = children;
  //     const [isReadMore, setIsReadMore] = useState(true);
  //     const toggleReadMore = () => {
  //       setIsReadMore(!isReadMore);
  //     };
  //     return (
  //       <p className="read-text">
  //         {isReadMore ? text.slice(0, 250) : text}
  //         <span onClick={toggleReadMore} className="read-or-hide">
  //           {!isReadMore ? " show less" : text.length > 250 && " ...read more "}
  //         </span>
  //       </p>
  //     );
  //   };

  let formData = new FormData();
  var uid = localStorage.getItem("userId");
  if (uid == null) {
    formData.append("user_id", "");
  } else {
    formData.append("user_id", uid);
  }

  useEffect(() => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .offers-main").css("opacity", ".1");
    formData.append("search_name", (dataObject.searchData && dataObject.searchData.search_name) || "");
    
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/getHome",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");

          //regularOffer filter
          setcartTotalPrice(res.data.cart_total)
          setfavoriteProduct(res.data.data.favorite_product);

          const categoryData = res.data.data.voucher_deals.filter(
            (val) => val.cat_id === location.state
          );
          console.log("res.data.data.popular_offer",res.data.data);
          console.log("res.data.data.voucher_deals",res.data.data.voucher_deals);
          if (categoryData.length > 0) {
            setVoucher(categoryData);
            setPopularOffer(categoryData);
            setProductData(categoryData);
          } else {
            setVoucher(res.data.data.voucher_deals);
            setPopularOffer(res.data.data.popular_offer);
            setProductData(res.data.data.voucher_deals);
          }
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");
          toast.error(ResponseMsg);
        }

      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  // Filter change handler
  const onFilter = (minprice, maxprice) => {
    
    const category_price = productData.filter(
      (val) =>
        (val.normal_price >= minprice && val.normal_price <= maxprice) ||
        (maxprice == -1 && val.normal_price >= minprice)
    );

    // setResEvenData(category_price)
  };

  //Category Filter
  const cat_id = (catId) => {
    const categoryData = productData.filter((val) => val.cat_id === catId);
    // setResEvenData(categoryData)
  };

  //Seller outletId
  const seller_outletId = (seller_outlletId) => {
    const outletId = productData.filter(
      (val) => val.outlet_id == seller_outlletId
    );
  };
  //TagFilter
  const handleTagFilter = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) {
        return val;
      } else {
        var tagArr = val.v_tag.split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = tagArr[m].trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }
      }
    });
  };

  // Deal specialOfferTags (For Demo)
  const specialOffer = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("radius", 50);

    axios
      .post("/Offer/getSpecialOffer", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        // setTagName("");

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
        }
      })
      .catch((err) => {
        $("#loadSpin").attr("hidden", "hidden");
        $(".map ").css("opacity", "1");
      });
  };

  useEffect(() => {
    specialOffer();
  }, []);

  // toggle Deal-voucher
  $(".deal-voucher .deal-header h4").on("click", function () {
    $(".deal-voucher .deal-header h4").removeClass("deal");
    $(this).addClass("deal");
  });

  // toggle image
  const imgPath = {
    catalog: catalog,
    blank_catalog: blank_catalog,
  };

  //Deal filter
  const handleTagFilterDeal = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) {
        return val;
      } else {
        var tagArr = val.v_tag.split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = tagArr[m].trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }
      }
    });
  };
  const searchTag = (product_tag) => {
    const currentIndex = tagClick.indexOf(product_tag);
    const newClick = [...tagClick];
    if (currentIndex === -1) {
      newClick.push(product_tag);
    } else {
      newClick.splice(currentIndex, 1);
    }
    setTagClick(newClick);
    handleTagFilterDeal(newClick);
  };
  return (
    <MainContainer
      onFilter={onFilter}
      seller_outletId={seller_outletId}
      cat_id={cat_id}
      handleTagFilter={(filter) => handleTagFilter(filter, "product_tag")}
    >
      <div
        id="loadSpin"
        className="spinner-border community-spinner"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden
      ></div>

      <div
        className={`${!dataObject.toggle ? "home-margin" : ""
          } offers-main reward-main p-3`}
      >
        <Row className="m-0">
          <Col lg={9} md={12}>
            <div className="bonus-point">
              <div className="bonus-banner">
                <img
                  style={{
                    textAlign: "center",
                    margin: "0 auto",
                    width: "100%",
                  }}
                  src={require("../assets/img/bonusbanner.svg").default}
                  alt=""
                />
              </div>
              <Row className=" justify-content-between">
                <Col md={6}>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <h2 className="reworddesc"><b> YOUR COUPONS,<br /> REWARDS & BONUS </b></h2>
                    </Col>
                    <Col md={6}>
                      <input className="searchinput" type="text" placeholder="SEARCH..." />
                    </Col>
                    <Col md={6}>
                      <div className="reword" >
                        <h2>POINTS</h2>
                        <div className="box"><img src={require("../assets/img/Crown.svg").default} alt="" />&nbsp; <h3>89.09</h3></div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="reword reword-bonus">
                        <h2>BONUS</h2>
                        <div className="box"><img src={require("../assets/img/ExpensivePrice.svg").default} alt="" />&nbsp;  <h3>1.263</h3></div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="mt-3 d-flex justify-content-between align-self-end">
                  <div className="rewordoption">
                    <img src={require("../assets/img/reword1.png").default} alt="" />
                    <h5>HOT<br></br>REWARDS</h5>
                  </div>
                  <div className="rewordoption">
                    <img src={require("../assets/img/Deal.png").default} alt="" />
                    <h5>WEEKLY<br></br>BONUS DEALS</h5>
                  </div>
                  <div className="rewordoption">
                    <img src={require("../assets/img/reword2.png").default} alt="" />
                    <h5>NEARBY <br></br> REWARDS</h5>
                  </div>
                  <div className="rewordoption">
                    <img src={require("../assets/img/calenderreword.png").default} alt="" />
                    <h5>CALENDAR <br></br> REWARDS</h5>
                  </div>
                  <div className="rewordoption">
                    <img src={require("../assets/img/Laurel Wreath.png").default} alt="" />
                    <h5>GRAND <br></br> PRIZE</h5>
                  </div>
                </Col>

                <Col md={6} className="my-lg-4 my-3 discount-img-box">
                  <Row>
                    {voucher && voucher.length > 0 && voucher.map((data) => (
                      <>
                        <Col sm={12} className="cursor-pointer" onClick={() => navigate(`/productdetail/${data.prod_id}`)}>
                          <img src={data && data.images.length > 0 ? data.images[0].v_image : require("../assets/img/InstaSale.png").default} className="w-100 food-ad-img" alt="" />
                          <h4>COUPON FOR 1 FOR FREE {data.v_title}</h4>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Col>
                <Col md={6} className="my-lg-4 my-3 cursor-pointer">
                  <Row>
                    {popularOffer && popularOffer.length > 0 && popularOffer.map((data) => (
                      <>
                        <Col sm={12} onClick={() => navigate(`/productdetail/${data.product_id}`)}>
                          <img src={data && data.images.length > 0 ? data.images[0].offer_image : require("../assets/img/InstaSale.png").default} className="w-100 food-ad-img" alt="" />
                          <h4>#SHARE ON YOUR SOME PROFILE & GET BONUS + POINTS</h4>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Col>


                <Col md={12} className="my-lg-4 my-3">
                  <img src={require("../assets/img/rewodsingup.png").default} className="w-100 food-ad-img" alt="" />
                  <h4>SIGNUP REWARD - GET 3 SIGNUPS TO INSTASALE AND GET 5 x 5 COCKTAILS - FOR FREE</h4>
                </Col>
                <Col md={6} className="my-2 discount-img-box">
                  <img src={require("../assets/img/discount.png").default} className="w-100 offerimg" alt="" />
                </Col>
                <Col md={6} className="my-2">
                  <img src={require("../assets/img/fridayoffer.png").default} className="w-100 offerimg" alt="" />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="offers-right">
              <div className="your_cart" onClick={() => navigate("/cart")}>
                <div className=" d-flex justify-content-between align-items-center">
                  <h4>Your Cart</h4>
                  <img
                    className="cart_img"
                    src={
                      cartImg == "" || cartImg == null
                        ? require("../assets/img/InstaSale.png").default
                        : cartImg
                    }
                    //   {require("../assets/img/burger_king.png").default}
                    alt=""
                  />
                </div>
                instasale
                <div className="cart-per d-flex justify-content-between align-items-center">
                  <img
                    src={require("../assets/img/your_cart.png").default}
                    onClick={() => navigate("/cart")}
                    alt=""
                  />
                  <span>
                    <p>KR.</p>
                    {cartTotalPrice == "" || cartTotalPrice == null
                      ? "0"
                      : cartTotalPrice}
                  </span>
                </div>
              </div>

              <div className="cash mt-3 text-center">
                <h4>Favourite Shops</h4>
                <div className="fav-img d-flex justify-content-center ">
                  {favoriteProduct == null || favoriteProduct == ""
                    ? ""
                    : favoriteProduct &&
                    favoriteProduct.map((data, i) => (
                      <img
                        className="mt-2"
                        key={i}
                        src={data.images[0].img}
                        alt=""
                      />
                    ))}
                </div>
              </div>
              <div className="social mt-3 text-center">
                <img
                  src={require("../assets/img/mini-logo.png").default}
                  alt=""
                />
                <h2 className="mt-3">DOWNLOAD </h2>
                <h2>INSTASALE APP</h2>
                <img
                  className="w-100"
                  src={require("../assets/img/phone.png").default}
                  alt=""
                />
                <div className="social-icon d-flex justify-content-center">
                  <Button type="button">
                    <BsApple style={{ color: "white" }} />
                  </Button>
                  <Button type="button">
                    <FaGooglePlay style={{ color: "white" }} />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MainContainer>
  );
};

export default BonusReword;
