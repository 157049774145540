import React, { useEffect, useState } from "react";
import { AiFillFacebook } from "react-icons/ai";
import FacebookLogin from 'react-facebook-login';
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useDispatch } from "react-redux";

var md5 = require("md5");

const Login = () => {
  const navigate = useNavigate();
  const getToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  // const { counter, dataObject } = useSelector(state => state)
  const { dataObject } = useSelector((state) => state);

 

  const submitForm = async (e) => {
    e.preventDefault();

    if (!name.trim() ) {
      toast.error("Please enter username.");
      return;
    }
    if (!password.trim()) {
      toast.error("Please enter password.");
      return;
    }

    let formData = new FormData();
    formData.append("name", name);
    formData.append("password", md5(password));

    axios
      .post("/User/login", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          toast.success(ResponseMsg);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("userId", res.data.data.user_id);
          localStorage.setItem("user", JSON.stringify(res.data.data));
          navigate("/");
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const loginWithFacebook = (data, navigate) => {
    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("is_fb", 1);
    formData.append("name", name);
    formData.append("password", md5(password));

    return (dispatch) => {
      dispatch({ type: "LOGIN_FETCH_PENDING" });
      axios
        .post("/User/login", formData, {
          headers: {
            key: "42e4fb9cdf992102e67d3098848b7c28",
          },
        })
        .then((res) => {
          dispatch({ type: "LOGIN_FETCH_SUCCESS" });
          if (res.data.ResponseCode == 1) {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("userId", res.data.data.user_id);
              localStorage.setItem("user", JSON.stringify(res.data.data));
            toast.success(res.data.ResponseMessage);
            navigate("/");
          } else {
            toast.error(res.data.ResponseMessage);
          }
        })
        .catch((error) => {
          dispatch({ type: "LOGIN_FETCH_FAILURE" });
          toast.error(error);
        });
    };
  };
  useEffect(() => {
    if (getToken) {
      navigate("/");
    }
  });
  
  // const responseFacebook = (data) => {
  //   console.log('loginData', data);
  //   data.fb_id = data.id;
  //   data.name = data.name;
  //   data.email = data.email;
  //   if (data.accessToken) {navigate('/')}
  //   dispatch(loginWithFacebook(data, navigate));  
  // }

  const responseFacebook = (data) => {
    data.fb_id = data.id;
    data.name = data.name;
    data.email = data.email;

      if (data.accessToken) {
        navigate("/");
        dispatch(loginWithFacebook(data, navigate));  
      } else {
        
      }
        
  };
  return (
    <>
      <Header />
      <div className="login">
        <div className="box-signup d-flex align-items-center justify-content-center">
          <div className=" boxer-sign ">
            <h1 className="mb-2 text-center">LOG IN</h1>
            <p className="text-center mb-4">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>

            <form onSubmit={submitForm}>
              <div className="form-group mb-4">
                <label htmlFor=" ">Username</label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="form-control border-0 shadow-none"
                  id=""
                  placeholder="Steve Johnson"
                />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className="form-control border-0 shadow-none"
                  id=""
                  placeholder="*********"
                />
                <p className="text-right pass-for mt-1" onClick={()=>navigate('/forgotpassword')}>Forgot Password?</p>
              </div>

              <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                LOG IN
              </button>

              <div className="mt-4 OROR">
                <div className="or1"></div>
                <div className="or2"></div>
                <h5 className="text-center or-text">OR</h5>
              </div>

              <div className="m-auto text-center pt-4">
                <button
                  type="submit"
                  className="m-auto d-flex align-items-center justify-content-center btn border-0 text-white btn-fb text-center " >
             
                  <AiFillFacebook />
                  LOG IN WITH FACEBOOK
                </button>
              </div>
            </form>
            
              {/* <div className="m-auto text-center pt-4">
                <button
                  type="submit"
                  className="m-auto d-flex align-items-center justify-content-center btn border-0 text-white btn-facebook text-center ">
                  <AiFillFacebook />
                <FacebookLogin
                  appId="277244841139799" //only for local appId: 277244841139799
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook} />
                </button>
              </div>  */}
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
