import Button from '@restart/ui/esm/Button'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { BsApple } from 'react-icons/bs'
import { FaGooglePlay } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useNavigate } from 'react-router'
import fillarrow from "../assets/img/fillarrow.png";
import arrow from "../assets/img/arrow.png";
import Header from './Header'
import Slider from "react-slick";
import $ from "jquery";

const markers = [];

const Map = () => {
  const navigate = useNavigate();

  const [activeMarker, setActiveMarker] = useState(null);

  const { dataObject } = useSelector((state) => state);
  const [specialOffer, setSpecialOffer] = useState(null);
  const [outletMapData, setOutletMapData] = useState(null);
  const [popularOffer, setpopularOffer] = useState()
  const [outletTrue, setOutletTrue] = useState(true);
  const [poularTrue, setPopularTrue] = useState(true);
  const [specialTrue, setspecialTrue] = useState(true);
  const [favoriteProduct, setfavoriteProduct] = useState();
  const [cartImg, setcartImg] = useState();
  const [cartTotalPrice, setcartTotalPrice] = useState();
  const [productId, setProductId] = useState([]);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .map").css("opacity", ".1");
    navigator.geolocation.watchPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("long", position.coords.longitude);
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });


    // fetchData();
  }, []);

  $(".offsell-detail").on("click", function () {
    $(".offsell-detail").removeClass("offsells");
    $(this).addClass("offsells");
  });

  let formData = new FormData();
  var uid = localStorage.getItem("userId");

  if (uid == null) {
    $("#loadSpin").attr("hidden", "hidden");
    $(".navbar, .map").css("opacity", "1");
    formData.append("user_id", "");
  } else {
    $("#loadSpin").attr("hidden", "hidden");
    $(".navbar, .map").css("opacity", "1");
    formData.append("user_id", uid);
  }

  //getSpecialOffer
  const fetchData = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".map ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("radius", 50);

    axios
      .post("Offer/getSpecialOffer", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setSpecialOffer(res.data.data);
          setOutletTrue(false);
          setPopularTrue(false);
          setspecialTrue(true);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setOutletTrue(false);
          setPopularTrue(false);
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {

        console.log(err);
      });
  };

  //map offer
  const offerData = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".map ").css("opacity", ".1");

    const options = {
      method: "POST",
      url: "/Offer/getPopularoffer",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");

          setpopularOffer(res.data.popularoffer);
          setOutletTrue(false);
          setPopularTrue(true);
          setspecialTrue(false);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setOutletTrue(false);
          setspecialTrue(false);
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //map seller data
  const outletData = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".map ").css("opacity", ".1");
    axios
      .post("/Offer/getOutlet")
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setOutletMapData(res.data.data);
          setOutletTrue(true);
          setPopularTrue(false);
          setspecialTrue(false);
          
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setPopularTrue(false);
          setspecialTrue(false);
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
  }, [productId]);

  //getHome
  useEffect(() => {
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/getHome",
      // url: "https://kmphasisinfotech.com/Instasale/Api/User/getHome",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          //popularOffer
          setfavoriteProduct(res.data.data.favorite_product);
          //cart
          setcartImg(res.data.cart_outlet_image);
          setcartTotalPrice(res.data.cart_total);
        } else {
          // toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  //slider
  var settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  return (
    <Header>
      <div
        className={`${!dataObject.toggle ? "home-margin" : "offers-main"
          } offers-main pt-1 map-style`}
      >
        <Row className="m-0">
          <Col lg={9} md={12} className="position-relative map-detail-style">
            <div
              id="loadSpin"
              className="spinner-border map-spinner"
              style={{ width: "3rem", height: "3rem", zIndex: "1000" }}
              role="status"
              hidden
            ></div>
            
            <div className="map">
              <div className="offer-seller">
                <div
                  onClick={() => {
                    localStorage.getItem("token")
                      ? fetchData()
                      : navigate("/login");
                  }}
                  className="seller offsell-detail"
                >
                  <p>Offers</p>
                </div>
                <div
                  onClick={() => {
                    localStorage.getItem("token")
                      ? outletData()
                      : navigate("/login");
                  }}
                  //   toggle ? fetchData() outletData(): ;
                  // }}
                  className="seller offsell-detail"
                >
                  <p>Sellers</p>
                </div>
              </div>

              <GoogleMap
                id="map-style"
                zoom={10}
                center={{ lat: 21.2281242, lng: 72.8337699 }}
                onLoad={handleOnLoad}
                onClick={() => setActiveMarker(null)}
                mapContainerStyle={{ width: "100%", height: "100%" }}
              >
                {specialTrue
                  ? specialOffer &&
                  specialOffer.offers.map((val, i) => (
                    <Marker
                      icon={arrow}
                      key={i}
                      position={{ lat: +val.lat, lng: +val.lng }}
                      onClick={() => handleActiveMarker(i)}
                    >
                      {activeMarker === i ? (
                        <InfoWindow
                          onCloseClick={() => setActiveMarker(null)}
                        >
                          <div
                            key={i}
                            className="product-box"
                            onClick={() =>
                              navigate(`/productdetail/${val.product_id}`)
                            }
                          >
                            <div className="product-img ">
                              <div className="items-img">
                                <img
                                  src={val.images.length && val.images[0].img}
                                  alt={val.outletname}
                                />
                                <img className='specialOffer offerlogo' src={val.logo} alt="" />
                              </div>

                              <div className="product-def item-amount ">
                                <p title={val.outlet_name}>
                                  {val.outlet_name}
                                  {/* {val.product_id} */}
                                </p>
                                <div className="product-per d-flex justify-content-between">
                                  <span>{val.special_offer_price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))
                  : ""}

                {outletTrue
                  ? outletMapData &&
                  outletMapData.map((val, i) => (
                    <Marker
                      icon={arrow}
                      key={i}
                      className="outletid"
                      position={{ lat: +val.lat, lng: +val.lng }}
                      onClick={() => handleActiveMarker(i)}
                    >
                      {activeMarker === i ? (

                        <InfoWindow
                          className="outletmarker"
                          onCloseClick={() => setActiveMarker(null)}>

                          <div key={i} className="product-box">
                            <div className="product-img ">
                              <div
                                className="items-img outlet-img"
                              >
                                <img
                                  className='offerlogo'
                                  src={
                                    val.logo == "" || val.logo == null
                                      ? require("../assets/img/InstaSale.png").default
                                      : val.logo
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="product-def item-amount ">
                                <p title={val.outlet_name}>
                                  {val.outlet_name}
                                </p>

                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))
                  : ""}
              </GoogleMap>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="offers-right">
              <div className="your_cart" onClick={() => navigate("/cart")}>
                <div className=" d-flex justify-content-between align-items-center">
                  <h4>Your Cart</h4>
                  <img
                    className="cart_img"
                    src={
                      cartImg == "" || cartImg == null
                        ? require("../assets/img/InstaSale.png").default
                        : cartImg
                    }
                    //   {require("../assets/img/burger_king.png").default}
                    alt=""
                  />
                </div>
                <div className="cart-per d-flex justify-content-between align-items-center">
                  <img
                    src={require("../assets/img/your_cart.png").default}
                    onClick={() => navigate("/cart")}
                    alt=""
                  />
                  <span>
                    <p>KR.</p>{cartTotalPrice == "" || cartTotalPrice == null
                      ? "0"
                      : cartTotalPrice}
                  </span>
                </div>
              </div>
             
              <div className="cash mt-3 text-center">
                <h4>Favourite Shops</h4>
                <div className="fav-img d-flex justify-content-center ">
                  {favoriteProduct == null || favoriteProduct == ""
                    ? ""
                    : favoriteProduct && favoriteProduct.map((data, i) => {
                      return data.images.map((img) => (
                        <img
                          className="mt-2 "
                          key={i}
                          src={img.img}
                          alt=""
                        />
                      ))
                    })}
                 
                </div>
              </div>
              <div className="social socialDownload  mt-3 text-center">
                <div>
                <img
                  src={require("../assets/img/mini-logo.png").default}
                  alt=""
                />
                
                  <h2 className="mt-3">DOWNLOAD </h2>
                  <h2>INSTASALE APP</h2>
                
                <div className="social-main-image">
                  <img
                    className="insta-app"
                    src={require("../assets/img/phone.png").default}
                    alt=""
                  />
                  </div>
                </div>
                <div className="social-icon d-flex justify-content-center">
                  <Button type="button">
                    <BsApple style={{ color: "white" }} />
                  </Button>
                  <Button type="button">
                    <FaGooglePlay style={{ color: "white" }} />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Header>
  );
}

export default Map

