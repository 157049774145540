import React, { useEffect, useState } from 'react'
import $ from "jquery"
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REDUX_ACTIONS } from '../../Redux/Actions/DataObjectAction';
import downArrorw from '../../assets/img/Union.png'
import { useForm } from "react-hook-form";
import profile from "../../assets/img/ic_profile.png"
import Header from '../Header';
const Account = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } });
    }, []);

    const navigate = useNavigate();
    const { dataObject } = useSelector((state) => state);
    const [userProfile, setUserProfile] = useState('');

    var user = JSON.parse(localStorage.getItem("user"));
    var uid = localStorage.getItem("userId");
    const UpdateProfile = async () => {
        setUserProfile(user.profile);
    }
    useEffect(() => {
        async function fetchData() {
            if (uid == null) {
                navigate('/')
            } else {
                await UpdateProfile();
            }
        }
        fetchData();
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submitForm = async (data) => {
        $("#loadSpin").removeAttr("hidden", "hidden");
        $(".navbar, .box-profile ").css("opacity", ".1");
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        var token = localStorage.getItem("token");
        formData.append("user_id", user.user_id);
        formData.append("profile", userProfile);


        const options = {
            method: "POST",
            headers: { token: localStorage.getItem("token") },
            data: formData,
            url: "/User/updateProfile",
        };
        axios(options)
            .then((res) => {
                const { ResponseMsg, ResponseCode, Result } = res.data;
                if (ResponseCode === 1 && Result) {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    toast.success(ResponseMsg);
                } else {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    toast.error(ResponseMsg);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <>

            <div className="profile-page">
                <div className="box-signup box-profile d-flex align-items-center justify-content-center">
                    <div className=" boxer-sign boxer-profile">
                        <div className="profile-setting">
                            <div className="profile-img">
                                <img
                                    src={
                                        userProfile == "" || userProfile == null
                                            ? require("../../assets/img/ic_profile.png").default
                                            : userProfile
                                    }
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = profile;
                                    }}
                                    alt=""
                                />
                            </div>
                        </div>


                        <form className='mt-3' onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-6 form-group mb-4">
                                    <label htmlFor=" ">First Name</label>
                                    <input
                                        type="text"
                                        //   onChange={(e) => setUserName(e.target.value)}
                                        defaultValue={user && user.fname}
                                        {...register("fname", {
                                            maxLength: 80,
                                        })}
                                        className="form-control border-0 shadow-none"
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="col-6 form-group mb-4">
                                    <label htmlFor=" ">Last Name</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.lname}
                                        {...register("lname")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Username</label>
                                    <input
                                        type="text"
                                        //   onChange={(e) => setUserName(e.target.value)}
                                        defaultValue={user && user.name}
                                        {...register("name", {
                                            maxLength: 80,
                                        })}
                                        className="form-control border-0 shadow-none"
                                        placeholder="username"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Email address</label>
                                    <input
                                        type="text"
                                        readOnly="true"
                                        defaultValue={user && user.email}
                                        {...register("email", {
                                            maxLength: 80,
                                        })}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Enter Email"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Mobile Phone</label>
                                    <input
                                        type="text"
                                        // readOnly
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        defaultValue={user && user.mobile_number}
                                        {...register("mobile_number")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Mobile Phone"
                                    />
                                </div>
                                <div className="form-group mb-4 gender">
                                    <label htmlFor="">Gender</label>
                                    <img src={downArrorw} alt="" />
                                    <select
                                        //   onChange={(e) => setUserGender(e.target.value)}
                                        className="form-control border-0 shadow-none"
                                        defaultValue={user && user.gender}
                                        {...register("gender")}
                                        placeholder="Select Gender"
                                    >
                                        <option value="" disabled>
                                            Select Gender
                                        </option>
                                        <option name="male" id="" value="Male">
                                            Male
                                        </option>
                                        <option name="female" id="" value="Female">
                                            Female
                                        </option>
                                    </select>
                                </div>
                            </div>




                            

                            <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                                UPDATE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Account