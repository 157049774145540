import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import isLogIn from "./Islogin";
import Header from "./Header";

var md5 = require("md5");

const Forgot_Password = () => {
  const navigate = useNavigate();
//   const [newPassword,setNewPassword]=useState('')
  const [userEmail, setUserEmail] = useState("");
  const { dataObject } = useSelector((state) => state);

  useEffect(() => {
    isLogIn(navigate);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (!userEmail.trim()) {
      toast.error("Please enter Email.");
      return;
    }

    let formData = new FormData();
    
    formData.append("email", userEmail);
      
    const options = {
      method: "POST",
      data: formData,
      url: "/User/forgotPassword",
    };

    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          toast.success(ResponseMsg);
          navigate("/login");
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header />
      <div className="login">
        <div className="box-signup d-flex align-items-center justify-content-center">
          <div className=" boxer-sign ">
            <h1 className="mb-2 text-center">Forgot Password</h1>

            <form onSubmit={submitForm}>
              <div className="form-group mb-4">
                <label htmlFor=" ">Email address</label>
                <input
                  type="email"
                  onChange={(e) => setUserEmail(e.target.value)}
                  value={userEmail}
                  className="form-control border-0 shadow-none"
                  id=""
                  placeholder="steve.Johnson@gmail.com"
                />
              </div>

              <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                Get Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot_Password;
