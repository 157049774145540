import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Header from "./Header";
import Account from "./TabContainer/Account";
import Address from "./TabContainer/Address";
import Company from "./TabContainer/Company";
import Password from "./TabContainer/Password";

function ControlledTabs() {
  const [key, setKey] = useState('User');
  const [nestKey, setNestKey] = useState('Account');

  return (
    <>
      <Header />
      <div
        id="loadSpin"
        className="spinner-border community-spinner"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden
      ></div>
      <div className="profile-tabs">
        <Tabs id="controlled-tab"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="User" title="User">
            <h1 className="mt-lg-4 mt-3 text-center">User Profile</h1>
            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Friends" title="Friends">
            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Orders" title="Orders" >

            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Payments" title="Payments" >

            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Creditcard" title="Creditcard" >

            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="BonusRewards" title="Bonus / Rewards" >
            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Settings" title="Settings" >
            <Tabs
              id="controlled-nest-tab"
              activeKey={nestKey}
              onSelect={(k) => setNestKey(k)}
              className="my-3 profile-nest-tabs"
            >
              <Tab eventKey="Account" title="Account">
                <Account />
              </Tab>
              <Tab eventKey="LocationAddress" title="Location / Address">
                <Address />
              </Tab>
              <Tab eventKey="Company" title="Company" >
                <Company />
              </Tab>
              <Tab eventKey="PasswordExtra" title="Password / Extra" >
                <Password />
              </Tab>
            </Tabs>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ControlledTabs