import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import useWindowDimensions from './useWindowDimensions'

const Sidebar = (props) => {
    const navigate = useNavigate()

    const [filters, updateFilters] = useState([]);
    const [resData, setResData] = useState([]);


    // const { height, width } = useWindowDimensions();
    const { width } = useWindowDimensions();


    useEffect(() => {
      if (width > 1024) {
          props.setToggle(true)
      } else {
          props.setToggle(false)
      }
    }, [width])

    useEffect(() => {
      axios.post("/Product/getCategory")
      .then(res => {
          const {ResponseMsg, ResponseCode, Result} = res.data
          if(ResponseCode === 1  && Result){
              setResData(res.data.data)     
          }else{
              toast.error(ResponseMsg)
          }
      })
      .catch(err => {
          console.log(err);
      })
    },[])


    return (
      <Fragment>
        <div className={`${!props.toggle ? "d-none" : "sidebar"} p-3`}>
          <div className="sidebar-menu">
            <span className="price-header">Category</span>
            <ul>
              {resData && resData.length > 0?
                resData.map((val, i) => (
                  <li
                    id='category'
                    key={i}
                  >
                    <input
                      type='checkbox'
                      style={{display:'non'}}
                      onChange={() => props.cat_id(val.cat_id)}
                      name="cat_id"
                    />
                    {val.cat_name}
                  </li>
                )) :
                ''
                }
            </ul>
          </div>

          <div className="price">
            <span className="price-header">Price</span>
            <ul>
              <li>
                <input
                  onChange={() => props.onFilter(0, 100)}
                  type="radio"
                  name="price_filter"
                />
                <span>0KR-100KR</span>
              </li>
              <li>
                <input
                  onChange={() => props.onFilter(100, 200)}
                  type="radio"
                  name="price_filter"
                />
                <span>100KR-200KR</span>
              </li>
              <li>
                <input
                  onChange={() => props.onFilter(200, 300)}
                  type="radio"
                  name="price_filter"
                />
                <span>200KR-300KR</span>
              </li>
              <li>
                <input
                  onChange={() => props.onFilter(300, 400)}
                  type="radio"
                  name="price_filter"
                />
                <span>300KR-400KR</span>
              </li>
              <li>
                <input
                  onChange={() => props.onFilter(400, 500)}
                  type="radio"
                  name="price_filter"
                />
                <span>400KR-500KR</span>
              </li>
              <li>
                <input
                  onChange={() => props.onFilter(500, -1)}
                  type="radio"
                  name="price_filter"
                />
                <span>{">"}500KR</span>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
}

export default Sidebar
