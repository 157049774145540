import React, { useEffect, useState } from 'react'
import $ from "jquery"
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REDUX_ACTIONS } from '../../Redux/Actions/DataObjectAction';
import downArrorw from '../../assets/img/Union.png'
import { useForm } from "react-hook-form";
import Header from '../Header';
const Address = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } });
    }, []);

    const navigate = useNavigate();
    const { dataObject } = useSelector((state) => state);

    var user = JSON.parse(localStorage.getItem("user"));
    var uid = localStorage.getItem("userId");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submitForm = async (data) => {
        $("#loadSpin").removeAttr("hidden", "hidden");
        $(".navbar, .box-profile ").css("opacity", ".1");
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        var token = localStorage.getItem("token");
        formData.append("user_id", user.user_id);


        const options = {
            method: "POST",
            headers: { token },
            data: formData,
            url: "/User/updateAddress",
        };
        axios(options)
            .then((res) => {
                const { ResponseMsg, ResponseCode, Result } = res.data;
                if (ResponseCode === 1 && Result) {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    localStorage.setItem("address", JSON.stringify(res.data.data));
                    toast.success(ResponseMsg);
                } else {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    toast.error(ResponseMsg);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <>

            <div className="profile-page">
                <div className="box-signup box-profile d-flex align-items-center justify-content-center">
                    <div className=" boxer-sign boxer-profile">

                        <form className='mt-3' onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Address</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.address && user.address.address}
                                        {...register("address")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Address"
                                    />
                                </div>
                                <div className="col-5 form-group mb-4">
                                    <label htmlFor=" ">Zip Code</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.address && user.address.zipcode}
                                        {...register("zipcode")}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Zip Code"
                                    />
                                </div>
                                <div className="col-7 form-group mb-4">
                                    <label htmlFor=" ">City</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.address && user.address.city}
                                        {...register("city")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="City"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Country</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.address && user.address.country}
                                        {...register("country")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Country"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Area</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.address && user.address.area}
                                        {...register("area")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Area"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Delivery note</label>
                                    <textarea
                                        className="form-control border-0 shadow-none"
                                        defaultValue={user && user.address && user.address.delivery_note !== "" && user.address.delivery_note}
                                        {...register("delivery_note")}
                                        cols="10"
                                        rows="5"
                                        placeholder='Add a note to help the driver find your delivery location or other important information...'>
                                    </textarea>
                                </div>
                            </div>






                            <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                                UPDATE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Address