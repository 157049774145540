import React, { useEffect, useState } from 'react'
import $ from "jquery"
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REDUX_ACTIONS } from '../../Redux/Actions/DataObjectAction';
import downArrorw from '../../assets/img/Union.png'
import { useForm } from "react-hook-form";
import Header from '../Header';
const Company = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } });
    }, []);

    const navigate = useNavigate();
    const { dataObject } = useSelector((state) => state);

    var user = JSON.parse(localStorage.getItem("user"));
    var uid = localStorage.getItem("userId");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submitForm = async (data) => {
        $("#loadSpin").removeAttr("hidden", "hidden");
        $(".navbar, .box-profile ").css("opacity", ".1");
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        var token = localStorage.getItem("token");
        formData.append("user_id", user.user_id);


        const options = {
            method: "POST",
            headers: { token: localStorage.getItem("token") },
            data: formData,
            url: "/User/updateCompany",
        };
        axios(options)
            .then((res) => {
                const { ResponseMsg, ResponseCode, Result } = res.data;
                if (ResponseCode === 1 && Result) {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    toast.success(ResponseMsg);
                } else {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    toast.error(ResponseMsg);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <>

            <div className="profile-page">
                <div className="box-signup box-profile d-flex align-items-center justify-content-center">
                    <div className=" boxer-sign boxer-profile">

                        <form className='mt-3' onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Company VAT No. (CVR)</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.VAT_no}
                                        {...register("VAT_no")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Company VAT No. (CVR)"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Company Phone</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.company_phone}
                                        {...register("company_phone")}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Company Phone"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Company Name</label>
                                    <input
                                        type="text"
                                        defaultValue={user && user.company_name}
                                        {...register("company_name")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Company Name"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Company email</label>
                                    <input
                                        type="email"
                                        defaultValue={user && user.company_email}
                                        {...register("company_email")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="Enter Company Email"
                                    />
                                </div>
                                <div className="form-group mb-4 gender">
                                    <label htmlFor="">Prefered delivery</label>
                                    <img src={downArrorw} alt="" />
                                    <select
                                        className="form-control border-0 shadow-none"
                                        defaultValue={user && user.prefered_delivery}
                                        {...register("prefered_delivery")}
                                    >
                                        <option value="" disabled>
                                            Select Prefered delivery
                                        </option>
                                        <option value="Delivered to address">
                                            Delivered to address
                                        </option>
                                        {/* <option value="Female">
                                            Female
                                        </option> */}
                                    </select>
                                </div>
                                <div className="form-group mb-4 gender">
                                    <label htmlFor="">Prefered region</label>
                                    <img src={downArrorw} alt="" />
                                    <select
                                        className="form-control border-0 shadow-none"
                                        defaultValue={user && user.prefered_region}
                                        {...register("prefered_region")}
                                    >
                                        <option value="" disabled>
                                            Select Prefered region
                                        </option>
                                        <option value="ROSKILDE">
                                            ROSKILDE
                                        </option>
                                        {/* <option value="Female">
                                            Female
                                        </option> */}
                                    </select>
                                </div>
                            </div>






                            <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                                UPDATE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Company