import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import Button from "@restart/ui/esm/Button";
import { useSelector } from "react-redux";
import PaymentModal from "./PaymentModal";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "./Header";
import $ from 'jquery';

const Cart = () => {
  // const { counter, dataObject } = useSelector(state => state)
  const { dataObject } = useSelector((state) => state);
  const navigate = useNavigate();
  const [cartData, setCartdata] = useState([]);
  const [voucher, setVoucher] = useState('');
  const [subtotal, setSubtotal] = useState();
  const [total, setTotal] = useState();

  const redeemVoucher = () => {
    if (!(voucher.trim())) {
      toast.error("Please enter voucher code");
    } 
  }
  var uid = localStorage.getItem("userId");
  useEffect(async () => {
    if (uid == null) {
      navigate("/login");
    } else {
      await apiData();
    }
  }, []);

  const apiData = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".table ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/myCart",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
        $("#loadSpin").attr("hidden", "hidden");
        $(".table ").css("opacity", "1");
          setCartdata(res.data.data.items);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".table ").css("opacity", "1");
          toast.error(ResponseMsg);
          toast.clearWaitingQueue();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var sbtotal = Number(0);
  var totalorder = Number(0);
  useEffect(() => {
    cartData.map((val, index) => {      
      sbtotal += (Number(val.price) * Number(val.quantity));
      totalorder += Number(val.price) * Number(val.quantity);
    })
    setSubtotal(sbtotal);    
    setTotal(totalorder);
  }, [cartData]);

  const changeQuantityValue = (cartId, opration) => {
    
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".table ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("cart_id", cartId);
    formData.append("type", opration);
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/plusMinusQuantity",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          
        $("#loadSpin").attr("hidden", "hidden");
        $(".table ").css("opacity", "1");
          apiData();
        } else {
        $("#loadSpin").attr("hidden", "hidden");
        $(".table ").css("opacity", "1");
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteItem = (cartId) => {
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("cart_id", cartId);
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/deleteCart",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          apiData();
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Header>
      <div
        className={`${
          !dataObject.toggle ? "home-margin" : "offers-main"
        } offers-main pt-3  cart-items`}
      >
        <div
          id="loadSpin"
          className="spinner-border"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
          hidden
        ></div>
        {cartData.length > 0 && (
          <>
            <Table responsive className="table">
              <thead className="table_heading">
                <tr>
                  <th>PRODUCT</th>
                  <th>PRICE</th>
                  <th>QTY</th>
                  <th>UNIT PRICE</th>
                </tr>
              </thead>
              <tbody>
                {cartData.length &&
                  cartData.map((val, index) => (
                    <tr className="table-row" key={index}>
                      <td>
                        <div className="d-flex align-items-center gap">
                          <Button
                            className="close-icon"
                            onClick={(e) => deleteItem(val.cart_id)}
                          >
                            <MdOutlineClose />
                          </Button>
                          <img src={val.img} alt="" className="img-small" />
                          <span>{val.name}</span>
                        </div>
                      </td>
                      <td> {`${val.price} KR.`}</td>
                      {/* special_offer_price */}
                      <td>
                        <div className="in-dc-btn d-flex align-items-center justify-content-between">
                          <Button
                            type="button"
                            onClick={(e) =>
                              changeQuantityValue(val.cart_id, "minus")
                            }
                          >
                            <img
                              className="dec"
                              src={require("../assets/img/dec.png").default}
                              alt=""
                            />
                          </Button>
                          <span>{val.quantity}</span>
                          <Button
                            type="button"
                            onClick={(e) =>
                              changeQuantityValue(val.cart_id, "plus")
                            }
                          >
                            +
                          </Button>
                        </div>
                      </td>
                      <td> {`${val.price * val.quantity} KR.`}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className="voucher-code mt-4">
                  <input
                    onChange={(e) => setVoucher(e.target.value)}
                    value={voucher}
                    placeholder="Voucher code"
                    type="text"
                  />
                  <Button onClick={redeemVoucher} type="button">
                    Redeem
                  </Button>
                </div>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <div className="voucher-list mt-5">
                  {/* {total}
                  {cartData.length &&
                    cartData.map((val, index) => ( */}
                  <React.Fragment>
                    <div className="per-list">
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Subtotal</span>
                        <span>{`${subtotal} KR.`}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <span>Shipping fee</span>
                        <span>20 KR.</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <span>Coupon</span>
                        <span>No</span>
                      </div>
                    </div>
                    <div className="total-per d-flex align-items-center justify-content-between mt-4">
                      <span>TOTAL</span>
                      <span> {`${total + 20} KR.`}</span>
                    </div>
                    {/* <Button type="button">Check out</Button> */}
                    <PaymentModal
                      price={total + 20}
                      // cardId={val.cart_id}
                      apidatafun={apiData}
                    />
                  </React.Fragment>
                  {/* ))} */}
                </div>
              </Col>
            </Row>
          </>
        )}
        {cartData.length <= 0 && (
          <center>
            <h1 className="no-item">No Items.</h1>
          </center>
        )}
      </div>
    </Header>
  );
};

export default Cart;
