import React, { Fragment, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../Redux/Actions/DataObjectAction';
import Sidebar from './Sidebar'
import { useNavigate } from "react-router-dom";
import MainContainerHeader from './MainContainer_Header';

export default function MainContainer(props) {

    const dispatch = useDispatch()
    const [toggle, setToggle] = useState(false);
    const [catId, setCatId] = useState();
    
    const navigate = useNavigate();

    const [showLanguageModel, setShowLanguageModel] = useState(false);
    const [country, setCountry] = useState(
      JSON.parse(localStorage.getItem("country"))
    );
    const [search, setSearch] = useState("");

    const options = [
      { value: "DK", label: "Denmark" },
      { value: "US", label: "United States" },
    ];

 useEffect(() =>{
        if(window.location.hash === "#/login" || window.location.hash === "#/signup"){
            setToggle(false)
        }
    },[])

    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: toggle } })
    }, [toggle])
    
   

    
    return (
      <Fragment>
        <MainContainerHeader toggle={toggle} setToggle={setToggle}
          handleTagFilter={props.handleTagFilter} cat_id={props.cat_id} seller_outletId={props.seller_outletId}  />
        <div className="d-flex">
          <Sidebar
            toggle={toggle}
            setToggle={setToggle}
            setCatId={setCatId}
            catId={catId}
            onFilter={props.onFilter}
            cat_id={props.cat_id}
          />
          <div className="w-100">{props.children}</div>
        </div>
      </Fragment>
    );
}
