import { createStore, applyMiddleware } from "redux";
import allReducer from "./CombineReducer"
import thunk from "redux-thunk";

// Store => Globalized State
const Store = createStore(
      allReducer,
      applyMiddleware(thunk)
)

export default Store