import { REDUX_ACTIONS } from "../Actions/DataObjectAction"

// Reducer => 
const DataObjectReducer = (state = {}, action) => {
      switch (action.type) {
            case REDUX_ACTIONS.TOGGLE:
                  return { ...state, toggle: action.payload.toggle }
            case REDUX_ACTIONS.SEARCH:
                  return { ...state, searchData: action.payload }
            default:
                  return state
      }
}

export default DataObjectReducer