import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Tabs, Tab } from 'react-bootstrap'
import { BsApple } from 'react-icons/bs'
import { FaGooglePlay } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MainContainer from './MainContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import catalog from '../assets/img/catalog.png';
import blank_catalog from '../assets/img/blank_catalog.png';

export default function Deal() {
  const navigate = useNavigate();
  const location = useLocation();

  const { dataObject } = useSelector((state) => state);
  const [voucherData, setVoucherData] = useState([]);
  const [favoriteProduct, setfavoriteProduct] = useState();
  const [cartImg, setcartImg] = useState();
  const [cartTotalPrice, setcartTotalPrice] = useState();
  const [productData, setProductData] = useState([]);
  const [catalogData, setCatalogData] = useState([])


  const [offerTagData, setOfferTagData] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [outletSellerData, setOutletSellerData] = useState(null);
  const [tagClick, setTagClick] = useState([]);

  const [key, setKey] = useState('catalog');
  const [filterTagData, setFilterTagData] = useState({
    product_tag: [],
    price: []
  });

  // read more
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="read-text">
        {isReadMore ? text && text.slice(0, 250) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {!isReadMore ? " show less" : text && text.length > 250 && " ...read more "}
        </span>
      </p>
    );
  };

  let formData = new FormData();
  var uid = localStorage.getItem("userId");
  if (uid == null) {
    formData.append("user_id", "");
  } else {
    formData.append("user_id", uid);
  }

  useEffect(() => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .offers-main").css("opacity", ".1");
    formData.append("search_name", (dataObject.searchData && dataObject.searchData.search_name) || "");

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/getHome",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");

          //catalogData filter
          const categoryData = res.data.data.regular_offer.filter(
            (val) => val.cat_id === location.state
          );
          console.log("categoryDataaddTocart",res.data);
          setcartTotalPrice(res.data.cart_total)
          if (categoryData.length > 0) {
            setCatalogData(categoryData);
            setProductData(categoryData);
          } else {
            setCatalogData(res.data.data.regular_offer);
            setProductData(res.data.data.regular_offer);
          }
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");
          toast.error(ResponseMsg);
        }

      })
      .catch((err) => {
        console.log(err);
      });
    
  }, []);

  // Tags List
  const tags = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("radius", 50);

    axios
      .post("/Offer/getSpecialOffer", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        // setTagName("");

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");

          setOfferTagData(res.data.data);

        } else {
          // toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        $("#loadSpin").attr("hidden", "hidden");
        $(".map ").css("opacity", "1");
      });
  };
 
  const handleVoucher = () => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .offers-main").css("opacity", ".1");
    formData.append("search_name", (dataObject.searchData && dataObject.searchData.search_name) || "");

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/getHome",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");

          setfavoriteProduct(res.data.data.favorite_product);
          console.log("res.data.data.favorite_product",res.data.data.voucher_deals);
          const voucher_catData = res.data.data.voucher_deals.filter(
            (val) => val.cat_id === location.state
          );
          if (voucher_catData.length > 0) {
            setVoucherData(voucher_catData);
            setProductData(voucher_catData);
          } else {
            setVoucherData(res.data.data.voucher_deals);
            setProductData(res.data.data.voucher_deals);
          }

          setcartImg(res.data.cart_outlet_image);
          setcartTotalPrice(res.data.cart_total);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");
          toast.error(ResponseMsg);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  // Filter change handler
  const onFilter = (minprice, maxprice) => {
    const category_price = productData.filter(
      (val) =>
        (val.normal_price >= minprice && val.normal_price <= maxprice) ||
        (maxprice == -1 && val.normal_price >= minprice)
    );

    setVoucherData(category_price);
    setCatalogData(category_price);
    setOfferData(category_price);
    // setResEvenData(category_price)
  };

  //Category Filter
  const cat_id = (catId) => {
    const categoryData = productData.filter((val) => val.cat_id === catId);
    setVoucherData(categoryData);
    setCatalogData(categoryData);
    setOfferData(categoryData);
    // setResEvenData(categoryData)
  }

  //Seller outletId
  const seller_outletId = (seller_outlletId) => {
    const outletId = productData.filter((val) => val.outlet_id == seller_outlletId);

    setVoucherData(outletId);
    setCatalogData(outletId);
    setOfferData(outletId);
  }
  //TagFilter
  const handleTagFilter = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) {
        return val;
      }
      else {
        var tagArr = (val.v_tag || val.tags || val.offer_tag).split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = (tagArr[m]).trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }
      }
    });
    setVoucherData(product_tag);
    setCatalogData(product_tag);
    setOfferData(product_tag);
  }

  // Deal specialOfferTags
  const specialOffer = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("radius", 50);

    axios
      .post("/Offer/getSpecialOffer", formData)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        // setTagName("");

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          
          const voucher_catData = res.data.data.offers.filter(
          (val) => val.cat_id === location.state
          );
          console.log("setOfferDataAddToCart",res.data.data.offers);
          if (voucher_catData.length > 0) {
          setOfferData(voucher_catData);
          setProductData(voucher_catData)
          } else {
            setOfferData(res.data.data.offers);
            setProductData(res.data.data.offers)
          }
        } else {
          // toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        $("#loadSpin").attr("hidden", "hidden");
        $(".map ").css("opacity", "1");
      });
  };

  //OutLetData
  const outletData = () => {
    // $("#loadSpin").removeAttr("hidden", "hidden");
    // $(".map ").css("opacity", ".1");
    axios
      .post("/Offer/getOutlet")
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;

        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          setOutletSellerData(res.data.data);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".map ").css("opacity", "1");
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  // add to cart
  const addTocart = (data) => {
    console.log("product_id",data);
    if (!localStorage.getItem("token")) {
      toast.error("please login first");
      navigate("/login");
      return;
    }
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("price", data.normal_price);
    formData.append("outlet_id", data.outlet_id);
    if(data.product_id){
      formData.append("product_id", data.product_id);
    }else{
      formData.append("product_id", data.prod_id);
    }

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/addCart",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          navigate("/cart");
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    tags();
    outletData();
  }, []);
  // toggle Deal-voucher
  $(".deal-voucher .deal-header h4").on("click", function () {
    $(".deal-voucher .deal-header h4").removeClass("deal");
    $(this).addClass("deal");
  });

  // toggle image
  const imgPath = {
    catalog: catalog,
    blank_catalog: blank_catalog
  }

  //Deal filter
  const handleTagFilterDeal = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) {
        return val;
      }
      else {
        var tagArr = (val.v_tag || val.tags || val.offer_tag).split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = (tagArr[m]).trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }
      }
    });
    setVoucherData(product_tag);
    setCatalogData(product_tag);
    setOfferData(product_tag);
  }
  //Deal SearchTag
  const searchTag = (product_tag) => {
    const currentIndex = tagClick.indexOf(product_tag);
    const newClick = [...tagClick]
    if (currentIndex === -1) {
      newClick.push(product_tag)
    } else {
      newClick.splice(currentIndex, 1)
    }
    setTagClick(newClick)
    handleTagFilterDeal(newClick)
  };
  return (
    <MainContainer
      onFilter={onFilter}
      seller_outletId={seller_outletId}
      cat_id={cat_id}
      handleTagFilter={(filter) => handleTagFilter(filter, 'product_tag')}>
      <div
        id="loadSpin"
        className="spinner-border community-spinner"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden>
      </div>

      <div
        className={`${!dataObject.toggle ? "home-margin" : ""
          } offers-main deal-catalog p-3`}
      >
        <Row className="m-0">
          <Col lg={9} md={12}>
            <div className="offers-left">
              {/* <div className="offers-header text-center">
                <h1>
                  <span className="pink">INSTA</span>
                  <span className="orange">SALE -</span> instant offers, right
                  where you are...
                </h1>
              </div> */}

              <div className="vouchers py-2">
                <div className="main-deal">
                  {outletSellerData && outletSellerData.length > 0 && outletSellerData.map((data, i) => (
                    <div className="deal-box">
                      <div className="deal_img"><img src={data && data.logo == "" ? require("../assets/img/InstaSale.png").default : data.logo} alt='' /></div>
                      <div className="deal-detail">
                        <h6>{data.outlet_name}</h6>
                        <p className='detail-text' title={data.address}>{ data.address}</p>
                        <p>{ data.zip}</p>
                      </div>
                    </div>
                  ))}
                  
                </div>
                {/* <div className="deal-header d-flex align-items-center" >
                  <h4 className="mb-0 mt-1 deal"><p>MENU {`/`} CATALOG</p>
                    <Button>
                      <img className="catalog-img" src={require("../assets/img/catalog.png").default} alt="" />
                      <img className="default-img" src={imgPath.catalog} alt="" />
                        <img className="active-img" src={imgPath.blank_catalog} alt="" /> 
                    </Button>
                  </h4>
                </div> */}
                <div className="profile-tabs deal-voucher mt-4 flex-column">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="catalog"
                      title={<h4 onClick={()=>window.location.reload()} className="mb-0 fw-bold">MENU {`/`} CATALOG
                        <img className="catalog-img ms-2" src={require("../assets/img/catalog.png").default} alt="" />
                      </h4>}>
                      <div className="deal-tags">
                        {offerTagData && offerTagData.tags.length > 0 ? (
                          offerTagData.tags.map((item, i) => (

                            <div className="btnTag position-relative" key={i}>
                              <input type="checkbox"
                                checked={tagClick.indexOf(item.toString()) === -1 ? false : true}
                                onChange={() => searchTag(item.toString())}
                              />
                              <label >
                                <div className=" tag-select">{item.toString()}</div>
                              </label>
                            </div>
                          )
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="vouchers-main mt-3">
                        <Row>
                          <Col>
                            <div className="left-vouchers">
                              {catalogData && catalogData.length > 0
                                ? catalogData.map((data, i) => {
                                  return (
                                    <Row className="d-flex" key={i}>
                                      <Col lg={4} md={6} className="voucher-box h-100 mt-2" onClick={() => navigate(`/productdetail/${data.product_id}`)}>
                                        <img
                                          className="v-img w-100"
                                          src={
                                            data.images && data.images[0].offer_image
                                          }
                                          alt=""
                                        />
                                      </Col>
                                      <Col lg={6} md={6} className="voucher-text mt-2" >
                                        <div className="voucher_detail">
                                          <h5>{data.title}</h5>
                                          {data.offer_tag == ''
                                            ? <p style={{ opacity: '0' }}>No Description</p>
                                            : <ReadMore>{data.offer_tag}</ReadMore>
                                          }

                                        </div>
                                        <div className="d-flex flex-column">
                                          <ul className='foodlist'>
                                            {data.txt_line1 == "" ? "" : <li>{data.txt_line1}</li>}
                                            {data.txt_line2 == "" ? "" : <li>{data.txt_line2}</li>}
                                            {data.txt_line3 == "" ? "" : <li>{data.txt_line3}</li>}
                                          </ul>
                                          <div className='discountamount'>
                                            <h5>NORMAL {data.normal_price}</h5>
                                            <h5 className='discount'>SAVE {data.saving}</h5>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={2} md={12} className='voucher-btn mt-2'>
                                        <div className='priceset'>
                                          <h6>PRICE KR.</h6>
                                          <h1 className='krprice'>{data.special_offer_price}</h1>
                                        </div>
                                        <Button className="get" type="button" onClick={() => addTocart(data)}>
                                          BUY
                                        </Button>
                                      </Col>
                                    </Row>
                                  );
                                })
                                : <div className="no_data">
                                  <h3>No Item Found</h3>
                                </div>
                              }
                            </div>
                          </Col>
                        </Row>
                        </div>
                    </Tab>


                    <Tab eventKey="offers" title={<h4 onClick={specialOffer} className="mb-0 mt-0 ">SPECIAL OFFERS
                      <img className="ms-2" src={require("../assets/img/offer.svg").default} alt="" />
                    </h4>}>
                      <div className="deal-tags">
                        {offerTagData && offerTagData.tags.length > 0 ? (
                          offerTagData.tags.map((item, i) => (
                            <div className="btnTag position-relative" key={i}>
                              <input type="checkbox"
                                checked={tagClick.indexOf(item.toString()) === -1 ? false : true}
                                onChange={() => searchTag(item.toString())}
                              />
                              <label >
                                <div className=" tag-select">{item.toString()}</div>
                              </label>
                            </div>
                          )
                          )
                        ) : (
                          <h1>No Data Found</h1>
                        )}
                      </div>

                      <div className="vouchers-main mt-3">
                        <Row>
                          <Col>
                            <div className="left-vouchers">
                              {offerData && offerData.length > 0
                                ? offerData.map((data, i) => {
                                  return (
                                    <Row className="d-flex" key={i}>
                                      <Col lg={4} md={6} className="voucher-box h-100 mt-2" onClick={() => navigate(`/productdetail/${data.product_id}`)}>
                                        <img
                                          className="v-img w-100"
                                          src={
                                            data.images && data.images[0].img
                                          }
                                          alt=""
                                        />
                                      </Col>
                                      <Col lg={6} md={6} className="voucher-text mt-2" >
                                        <div className="voucher_detail">
                                          <h5>{data.title}</h5>
                                          {data.description == ''
                                            ? <p style={{ opacity: '0' }}>No Description</p>
                                            : <ReadMore>{data.description}</ReadMore>
                                          }

                                        </div>
                                        <div className="d-flex flex-column">
                                          <ul className='foodlist'>
                                            {data.txt_line1 == "" ? "" : <li>{data.txt_line1}</li> }
                                            {data.txt_line2 == "" ? "" : <li>{data.txt_line2}</li> }
                                            {data.txt_line3 == "" ? "" : <li>{data.txt_line3}</li> }
                                          </ul>
                                          <div className='discountamount'>
                                            <h5>NORMAL {data.normal_price}</h5>
                                            <h5 className='discount'>SAVE {data.saving}</h5>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={2} md={12} className='voucher-btn mt-2'>
                                        <div className='priceset'>
                                          <h6>PRICE KR.</h6>
                                          <h1 className='krprice'>{data.special_offer_price}</h1>
                                        </div>
                                        <Button className="get" type="button" onClick={() => addTocart(data)}>
                                          BUY
                                        </Button>
                                      </Col>
                                    </Row>
                                  );
                                })
                                : <div className="no_data">
                                  <h3>No Item Found</h3>
                                </div>
                              }
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Tab>


                    <Tab eventKey="vouchers" title={<h4 onClick={handleVoucher} className="mb-0 fw-bold">DEALS {`&`} VOUCHERS
                      <img className="catalog-img ms-2" src={require("../assets/img/deals.svg").default} alt="" />
                    </h4>} >
                      <div className="deal-tags">
                        {offerTagData && offerTagData.tags.length > 0 ? (
                          offerTagData.tags.map((item, i) => (
                            <div className="btnTag position-relative" key={i}>
                              <input type="checkbox"
                                checked={tagClick.indexOf(item.toString()) === -1 ? false : true}
                                onChange={() => searchTag(item.toString())}
                              />
                              <label >
                                <div className=" tag-select">{item.toString()}</div>
                              </label>
                            </div>
                          )
                          )
                        ) : (
                          <h1>No Data Found</h1>
                        )}
                      </div>
                      <div className="vouchers-main mt-3">
                        <Row>
                          <Col>
                            <div className="left-vouchers">
                      {voucherData && voucherData.length > 0
                        ? voucherData.map((data, i) => {
                          return (
                            <Row className="d-flex" key={i}>
                              <Col lg={4} md={6} className="voucher-box h-100 mt-2" onClick={() => navigate(`/productdetail/${data.prod_id}`)}>
                                <img
                                  className="v-img w-100"
                                  src={
                                    data.images && data.images[0].v_image
                                  }
                                  alt=""
                                />
                              </Col>
                              <Col lg={6} md={6} className="voucher-text mt-2" >
                                <div className="voucher_detail">
                                  <h5>{data.v_title}</h5>
                                  {data.long_desc == ''
                                    ? <p style={{ opacity: '0' }}>No Description</p>
                                    : <ReadMore>{data.long_desc}</ReadMore>
                                  }

                                </div>
                                <div className="d-flex flex-column">
                                  <ul className='foodlist'>
                                    {data.txt_line1 == "" ? "" : <li>{data.txt_line1}</li>}
                                    {data.txt_line2 == "" ? "" : <li>{data.txt_line2}</li>}
                                    {data.txt_line3 == "" ? "" : <li>{data.txt_line3}</li>}
                                  </ul>
                                  <div className='discountamount'>
                                    <h5>NORMAL {data.normal_price}</h5>
                                    <h5 className='discount'>SAVE {data.saving}</h5>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={2} md={12} className='voucher-btn mt-2'>
                                <div className='priceset'>
                                  <h6>PRICE KR.</h6>
                                  <h1 className='krprice'>{data.special_offer_price}</h1>
                                </div>
                                <Button className="get" type="button" onClick={() => addTocart(data)}>
                                  BUY
                                </Button>
                              </Col>
                            </Row>
                          );
                        })
                        : <div className="no_data">
                          <h3>No Item Found</h3>
                        </div>
                              }
                            </div>
                          </Col>
                        </Row>
                        </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="offers-right">
              <div className="your_cart" onClick={() => navigate("/cart")}>
                <div className=" d-flex justify-content-between align-items-center">
                  <h4>Your Cart</h4>
                  <img
                    className="cart_img"
                    src={
                      cartImg == "" || cartImg == null
                        ? require("../assets/img/InstaSale.png").default
                        : cartImg
                    }
                    alt=""
                  />
                </div>instasale
                <div className="cart-per d-flex justify-content-between align-items-center">
                  <img
                    src={require("../assets/img/your_cart.png").default}
                    onClick={() => navigate("/cart")}
                    alt=""
                  />
                  <span>
                    <p>KR.</p>{cartTotalPrice == "" || cartTotalPrice == null
                      ? "0"
                      : cartTotalPrice}
                  </span>
                </div>
              </div>
              <div className="cash mt-3 text-center"
                onClick={() => { navigate("/rewards") }}
              >
                <h4>Cash Referals</h4>
                <span>KR.33.75</span>
                <h4 className='points'>
                  Points <span>3594</span>
                </h4>
              </div>
              <div className="cash mt-3 text-center">
                <h4>Favourite Shops</h4>
                <div className="fav-img d-flex justify-content-center ">
                  {favoriteProduct == null || favoriteProduct == ""
                    ? ""
                    : favoriteProduct &&
                    favoriteProduct.map((data, i) => (
                      <img
                        className="mt-2"
                        key={i}
                        src={data.images[0].img}
                        alt=""
                      />
                    ))}
                </div>
              </div>
              <div className="social mt-3 text-center">
                <img
                  src={require("../assets/img/mini-logo.png").default}
                  alt=""
                />
                <h2 className="mt-3">DOWNLOAD </h2>
                <h2>INSTASALE APP</h2>
                <img className='' src={require("../assets/img/phone.png").default} alt="" />
                <div className="social-icon d-flex justify-content-center">
                  <Button type="button">
                    <BsApple style={{ color: "white" }} />
                  </Button>
                  <Button type="button">
                    <FaGooglePlay style={{ color: "white" }} />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MainContainer>
  );
}
