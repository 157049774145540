import React, { useEffect, useState } from 'react'
import MainContainer from './MainContainer'
import { useSelector } from 'react-redux';
import Button from '@restart/ui/esm/Button';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from "react-redux";
import $ from 'jquery';

export default function Offers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const [filters, updateFilters] = useState([]);
  const { dataObject } = useSelector((state) => state);

  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [responseData, setResponseData] = useState([]);
  const [productData, setProductData] = useState([]);
  // getProductsList
  useEffect(() => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .main-content ").css("opacity", ".1");
    let formData = new FormData();
    formData.append("lat", localStorage.getItem("lat"));
    formData.append("lng", localStorage.getItem("long"));
    formData.append("search_name",(dataObject.searchData && dataObject.searchData.search_name) || "");
    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Product/getProductsList",
    };

  axios(options)
    .then((res) => {
      const { ResponseMsg, ResponseCode, Result } = res.data;

      if (ResponseCode === 1 && Result) {
        $("#loadSpin").attr("hidden", "hidden");
        $(".navbar, .main-content ").css("opacity", "1");
        const categoryData = res.data.data.filter(
          (val) => val.category_id === location.state
        );

        if (categoryData.length > 0) {
          setResponseData(categoryData);
          setProductData(categoryData);
        } else {
          setResponseData(res.data.data);
          setProductData(res.data.data);
        }
      } else {
        $("#loadSpin").attr("hidden", "hidden");
        $(".navbar, .main-content ").css("opacity", "1");
        toast.error(ResponseMsg);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, [lat, long, dataObject.searchData]);

  // Filter change handler(price filtering)
  const onFilter = (minprice, maxprice) => {

    const category_price = productData.filter(
      (val) =>
        (val.special_offer_price >= minprice && val.special_offer_price <= maxprice) ||
        (maxprice == -1 && val.special_offer_price >= minprice)
    );

    setResponseData(category_price);
  };


  //Category Filter
  const cat_id = (catId) => {
    const categoryData = productData.filter((val) => val.category_id === catId);
    setResponseData(categoryData);
  }

  //Seller outletId
  const seller_outletId = (seller_outlletId) => {
    const outletId = productData.filter((val) => val.outlet_id == seller_outlletId);
    setResponseData(outletId);
  }

  //Tags (getSpecialOffer)
  const handleTagFilter = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) { 
        return val;
      }
      else
      {
        var tagArr = (val.product_tags).split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = (tagArr[m]).trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }  
      }
      
    });
    setResponseData(product_tag);
  }

  return (
    <MainContainer
      onFilter={onFilter}
      cat_id={cat_id}
      seller_outletId={seller_outletId}
      handleTagFilter={handleTagFilter}>
      <div
        id="loadSpin"
        className="spinner-border community-spinner"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden >
      </div>
      <div className="d-flex main-body p-3">
        {responseData && responseData.length > 0 ? (
          <>
            <div
              className={`${!dataObject.toggle ? "margin-left" : "main-content"
                } main-content p-3`}
            >
              {responseData.map((val, index) => (
                <figure key={index}>
                  <div
                    className="product-box"
                    onClick={() => navigate(`/productdetail/${val.product_id}`)}
                  >
                    <div className="product-img pb-2 p-2">
                      <LazyLoadImage
                        alt={val.title}
                        effect="blur"
                        src={val.images.length && val.images[0].img} // use normal <img> attributes as props
                      />
                    </div>
                    <div className="product-def p-2">
                      <div className="mc-icon">
                        <img src={val.outlet_logo} alt="" />
                      </div>
                      <h5>{val.title}</h5>
                      <div className="product-per d-flex justify-content-between">
                        <del>{val.normal_price}</del>
                        <span>{val.special_offer_price}</span>
                      </div>
                    </div>
                  </div>
                </figure>
              ))}
            </div>
            <div className="main-toggle">
              <Button>
                <img
                  src={require("../assets/img/catalog.png").default}
                  alt=""
                />
              </Button>
            </div>
          </>
        ) : (
          <div
            className={`${!dataObject.toggle ? "margin-left" : "main-content nodata-content"
              } main-content p-3`}>
          
            <h1>No Items Found</h1>
          </div>
        )}
      </div>
    </MainContainer>
  );
}
