import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REDUX_ACTIONS } from "../Redux/Actions/DataObjectAction";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { CountryDropdown } from "react-country-region-selector";
import NavbarHeader from "./Navbar_header";

export default function Header(props) {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const { dataObject } = useSelector((state) => state);

  const navigate = useNavigate();

  const [country, setCountry] = useState(
    JSON.parse(localStorage.getItem("country"))
  );
  const [search, setSearch] = useState("");

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("profile");
    navigate("/home");
  };

  const options = [
    { value: "DK", label: "Denmark" },
    { value: "US", label: "United States" },
  ];

  useEffect(() => {
    if (
      window.location.hash === "#/login" ||
      window.location.hash === "#/signup"
    ) {
      setToggle(false);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: toggle } });
  }, [toggle]);

  return (
    <Fragment>
      <NavbarHeader toggle={toggle} setToggle={setToggle} handleTagFilter={props.handleTagFilter} />
      <div className="d-flex">
        {/* <Sidebar
          toggle={toggle}
          setToggle={setToggle}
          setCatId={setCatId}
          catId={catId}
          onFilter={props.onFilter}
        /> */}
        <div className="w-100">{props.children}</div>
      </div>
    </Fragment>
  );
}

