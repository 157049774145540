import React, { useEffect, useState } from 'react'
import { AiFillFacebook } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import isLogIn from './Islogin';
import { useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../Redux/Actions/DataObjectAction';
import Header from './Header';
import downArrorw from '../assets/img/Union.png'

var md5 = require('md5');

const Signup = () => {

  const navigate = useNavigate();
  const getToken = localStorage.getItem("token");
  const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } })
    }, [])

    const { dataObject } = useSelector(state => state);
    const [ userName, setUserName ] = useState("");
    const [ userEmail, setUserEmail ] = useState("");
    const [ userGender, setUserGender ] = useState("");
    const [ userPassword, setuserPassword ] = useState("");
    
    
    useEffect( () => {
        isLogIn(navigate);
    },[])

    const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    const submitForm = async (e) => {
        e.preventDefault();
        if (!(userName.trim()) ) {
          toast.error("Please enter username.")
          return;
        }
        
        if (!userEmail.trim()) {
          toast.error("Please enter email.");
          return;
        } else if (!userEmail || validation.test(userEmail) === false) {
          toast.error("Please enter Valid Email.");
          return false;
        } 
        
        if ( !userGender.trim()) {
          toast.error("Please enter gender.")
          return;
        }
      
        if (!userPassword.trim()) {
          toast.error("Please enter password.");
          return;
        }
        
        let formData = new FormData();
        formData.append('name', userName);
        formData.append('email', userEmail);
        formData.append('gender', userGender);
        formData.append('password', md5(userPassword));

        axios.post("/User/signUp", formData )
          .then(res => {
          const {ResponseMsg, ResponseCode, Result} = res.data
            if (ResponseCode === 1 && Result) {
            toast.success(ResponseMsg)
            navigate('/login');      
            } else {
            toast.error(ResponseMsg)
          }
        })
        .catch(err => {
            console.log(err);
        })

  }
  
  const signUpWithFacebook = (data, navigate) => {
    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("is_fb", 1);
    formData.append('name', userName);
    formData.append('email', userEmail);
    formData.append('gender', userGender);
    formData.append('password', md5(userPassword));

    return (dispatch) => {
      dispatch({ type: "LOGIN_FETCH_PENDING" });
      axios
        .post("/User/login", formData, {
          headers: {
            key: "42e4fb9cdf992102e67d3098848b7c28",
          },
        })
        .then((res) => {
          dispatch({ type: "LOGIN_FETCH_SUCCESS" });
          if (res.data.ResponseCode == 1) {;
            toast.success(res.data.ResponseMessage);
            navigate("/login");
          } else {
            toast.error(res.data.ResponseMessage);
          }
        })
        .catch((error) => {
          dispatch({ type: "LOGIN_FETCH_FAILURE" });
          toast.error(error);
        });
    };
  };
  useEffect(() => {
    if (getToken) {
      navigate("/");
    }
  });
  const responseFacebook = (data) => {
    if (data.accessToken) { navigate('/login') }
    data.fb_id = data.id; 
    data.name = data.name;
    data.email = data.email;
    dispatch(signUpWithFacebook(data, navigate));
  }
    return (
      <Header>
        <div
          className={`${
            !dataObject.toggle ? "login-page" : "signup-main"
          } p-3 signUp-style`}
        >
          <div className="box-signup d-flex align-items-center justify-content-center">
            <div className=" boxer-sign">
              <h1 className="mb-2 text-center">SIGN UP</h1>
              <p className="text-center mb-4">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.{" "}
              </p>

              <form onSubmit={submitForm}>
                <div className="form-group mb-4">
                  <label htmlFor=" ">Username</label>
                  <input
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                    className="form-control border-0 shadow-none"
                    id=""
                    placeholder="Steve Johnson"
                  />
                </div>

                <div className="form-group mb-4">
                  <label htmlFor=" ">Email address</label>
                  <input
                    type="text"
                    onChange={(e) => setUserEmail(e.target.value)}
                    value={userEmail}
                    className="form-control border-0 shadow-none"
                    id=""
                    placeholder="steve.Johnson@gmail.com"
                  />
                </div>

                <div className="form-group mb-4 gender">
                  <label htmlFor="">Gender</label>
                  <img src={downArrorw} alt="" />
                  <select
                    onChange={(e) => setUserGender(e.target.value)}
                    className="form-control border-0 shadow-none"
                    value={userGender}
                    placeholder="Select Gender"
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option name="male" id="" value="Male">
                      Male
                    </option>
                    <option name="female" id="" value="Female">
                      Female
                    </option>
                  </select>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    onChange={(e) => setuserPassword(e.target.value)}
                    value={userPassword}
                    className="form-control border-0 shadow-none"
                    id=""
                    placeholder="*********"
                  />
                </div>

                <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                  SIGN UP
                </button>

                <div className="mt-4 OROR">
                  <div className="or1"></div>
                  <div className="or2"></div>
                  <h5 className="text-center or-text">OR</h5>
                </div>

                <div className="m-auto text-center pt-4">
                  <button
                    type="submit"
                    className="m-auto d-flex align-items-center justify-content-center btn border-0 text-white btn-fb text-center "
                  >
                    <AiFillFacebook />
                    SIGN UP WITH FACEBOOK
                  </button>
                </div>

              </form>

              {/* <div className="m-auto text-center pt-4">
                <button
                  type="submit"
                  className="m-auto d-flex align-items-center justify-content-center btn border-0 text-white btn-facebook text-center ">
                  <AiFillFacebook />
                  <FacebookLogin
                    textButton="Sign up with Facebook"
                    appId="277244841139799" //only for local appId: 277244841139799
                    autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook} />
                </button>
              </div>  */}

            </div>
          </div>
        </div>
      </Header>
    );
}

export default Signup
