import Button from '@restart/ui/esm/Button';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';


const PaymentModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [paymentState, setPaymentState] = useState(1)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNub, setPhoneNub] = useState('')
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [cardNub, setCardNub] = useState('')
  const [expiryMonth, setExpiryMonth] = useState('')
  const [expiryYear, setExpiryYear] = useState('')
  const [cvv, setCvv] = useState('')
  const [holderNub, setHolderNub] = useState('')
  const [resData, setResData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState([true, false, false])

  const user = JSON.parse(localStorage.getItem("user"));
  const defaultAddress = `${user.address.address},${user.address.area},${user.address.city},${user.address.country},${user.address.zipcode}.`
  console.log("userDataaa",user);
  const closeModal = () => {
      if (paymentState === 3) {
          props.apidatafun()
      }
      setModalShow(false)
      setPaymentState(1)
      window.location.reload();
  }

  const updateCardData=async()=>{
    if(user && (user.fname === null || user.fname === "null")){
      return "";
    }else{
      setFirstName(user.fname);
    }

    if(user && (user.lname === null || user.lname === "null")){
      return "";
    }else{
      setLastName(user.lname);
    }

    if(user && (user.mobile_number === null || user.mobile_number === "null")){
      return "";
    }else{
      setPhoneNub(user.mobile_number);
    }

    if(user && (user.email === null || user.email === "null")){
      return "";
    }else{
      setEmail(user.email);
    }

    if(user && (user.address === null || user.address === "null")){
      return "";
    }else{
      setAddress(defaultAddress);
    }

    
  }

  useEffect(() => {
   updateCardData();
  }, [])
  
  const validation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
  const goToPayment = () => {
    if (!(firstName.trim())) {
        toast.error("Please enter first name")
        return;
    }
    if (!email.trim()) {
      toast.error("Please enter email.");
      return;
    } else if (!email || validation.test(email) === false) {
      toast.error("Please enter Valid Email.");
      return false;
    } 
    if (!(lastName.trim()) ) {
        toast.error("Please enter last name")
        return;
    }
    if (!(address.trim())) {
        toast.error("Please enter your address")
        return;
    }
    if (!(phoneNub.trim()) ) {
        toast.error("Please enter phone number");
        return;
    }
    setPaymentState(2)
  }

  const confirm = () => {
    if (!(cardNub.trim()) || !(expiryMonth.trim()) || !(cvv.trim()) || !(holderNub.trim())) {
        toast.error("Please enter valid details.")
        return;
    }
    setIsLoading(true)

    let formData = new FormData();
    formData.append('user_id', localStorage.getItem('userId'));
    formData.append('total_price', props.price);
    formData.append('name', `${firstName} ${lastName}`);
    formData.append('phone', phoneNub);
    formData.append('email', email);
    formData.append('want_adres', address);
    formData.append('delivery', 'test');
    formData.append('card_number', cardNub);
    formData.append('exp_month', +expiryMonth);
    formData.append('exp_year', +expiryYear);
    formData.append('card_cvv', cvv);
    formData.append('card_holder_name', holderNub);

    const options = {
        method: 'POST',
        headers: { 'token': localStorage.getItem('token') },
        data: formData,
        url: '/Order/addOrder'
    };
    axios(options).then(res => {
      const { ResponseMsg, ResponseCode, Result } = res.data
      if (ResponseCode === 1 && Result) {
        setResData(res.data)
        setPaymentState(3)
        setIsLoading(false)
      } else {
        toast.error(ResponseMsg)
        setIsLoading(false)
      }
    }).catch(err => {
        console.log(err);
        setIsLoading(false)
      })
}

  const complete = () => {
    closeModal()
  }

  const backModal = () => {
    setPaymentState(1)
  }
  const backmodal2 = () => {
    setPaymentState(2);
  }

  const ChangePaymentMethod = (index) => {
    const newPaymentMethod = paymentMethod.map(data => data && false)
    setPaymentMethod(newPaymentMethod.map((data, i) => index === i ? true : data))
  }

  return (
    <span>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Check out
      </Button>

      <Modal
        show={modalShow}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        {paymentState === 1 && (
          <>
            <Modal.Header>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <Button type="button" className="close-modal">
                  <img
                    src={require("../assets/img/close-modal.png").default}
                    alt=""
                    onClick={closeModal}
                  />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body className="text-center pb-5">
              <span>Make Payment</span>
              <div className="steps-main d-flex my-4">
                <div className="step-by mx-auto d-flex gap">
                  <div className="step active">
                    <span>1</span>
                  </div>
                  <div className="step">
                    <span>2</span>
                  </div>
                  <div className="step">
                    <span>3</span>
                  </div>
                </div>
              </div>
              <Row className="mt-3">
                <Col lg={6} md={6} sm={12}>
                  <div className="form">
                    <input
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      // defaultValue={user.fname}
                      value={firstName}
                    />
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      // defaultValue={user.email}

                      className="mt-3"
                      type="text"
                      placeholder="Email Address"
                    />
                    <div className="payment-method">
                      <span>Select Method of Payment</span>
                      <div
                        className={`payment ${
                          paymentMethod[0] ? "active" : ""
                        } d-flex mt-2`}
                        onClick={() => ChangePaymentMethod(0)}
                      >
                        <img
                          src={require("../assets/img/card.png").default}
                          alt=""
                        />
                        <span>Credit Card Or Debit</span>
                        <input type="checkbox" />
                      </div>
                      <div
                        className={`payment ${
                          paymentMethod[1] ? "active" : ""
                        } d-flex mt-2`}
                        onClick={() => ChangePaymentMethod(1)}>
                      
                        <img
                          src={require("../assets/img/Paypal.png").default}
                          alt=""
                        />
                        <span>Paypal</span>
                        <input type="checkbox" />
                      </div>
                      <div
                        className={`payment ${
                          paymentMethod[2] ? "active" : ""
                        } d-flex mt-2`}
                        onClick={() => ChangePaymentMethod(2)}
                      >
                        <img
                          src={require("../assets/img/Bank.png").default}
                          alt=""
                        />
                        <span>Bank Transfer</span>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="form">
                    <form>
                      <input
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                      // defaultValue={user.lname}
                      value={lastName}
                        placeholder="Last Name"
                      />
                      <textarea
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      // defaultValue={defaultAddress}
                        className="mt-3"
                        placeholder="Address for Delivery"
                      ></textarea>
                      <input
                        maxLength="10"
                        onChange={(e) => setPhoneNub(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={phoneNub}
                        // defaultValue={user.mobile_number}
                        className="mt-3"
                        type="text"
                        placeholder="Mobile Phone"
                      />
                    </form>
                  </div>
                </Col>
              </Row>
              <Button className="mt-4" onClick={goToPayment}>
                Go to Payment
              </Button>
            </Modal.Body>
          </>
        )}
        {paymentState === 2 && (
          <>
            <Modal.Header>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <Button type="button" className='backbtn d-flex'>
                  <img
                    src={require("../assets/img/back-modal.png").default}
                    onClick={backModal}
                    alt=""
                  />
                </Button>
                <Button type="button">
                  <img
                    src={require("../assets/img/close-modal.png").default}
                    alt=""
                    onClick={closeModal}
                  />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body className="text-center pb-5">
              <span>Make Payment 2</span>
              <div className="steps-main d-flex my-4">
                <div className="step-by mx-auto d-flex gap">
                  <div className="step active">
                    <span>1</span>
                  </div>
                  <div className="step active">
                    <span>2</span>
                  </div>
                  <div className="step">
                    <span>3</span>
                  </div>
                </div>
              </div>
              <Row className="mt-3 align-items-center">
                <Col lg={6} md={6} sm={12}>
                  <div className="form">
                    <img
                      className="card-img"
                      src={require("../assets/img/CreditCard.png").default}
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="form">
                    <input
                      onChange={(e) => setCardNub(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={cardNub}
                      type="text"
                      placeholder="Card Number"
                    />
                    <div className="d-flex gap-2 mt-3">
                      <input
                        style={{ fontSize: "15px" }}
                        onChange={(e) => setExpiryMonth(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={expiryMonth}
                        placeholder="Expiry Month"
                        type="text"
                      />
                      <input
                        onChange={(e) => setExpiryYear(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={expiryYear}
                        placeholder="Expiry Year"
                        type="text"
                      />
                      <input
                        onChange={(e) => setCvv(e.target.value)}
                        value={cvv}
                        placeholder="CVV"
                        type="text"
                      />
                    </div>
                    <input
                      onChange={(e) => setHolderNub(e.target.value)}
                      value={holderNub}
                      className="mt-3"
                      type="text"
                      placeholder="Holder Name"
                    />
                    <div className="save-credit mt-2">
                      <input
                        className="me-1"
                        type="checkbox"
                        id="save_card"
                      />
                      <label htmlFor="save_card">Save this credit card</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Button className="mt-5" onClick={confirm} disabled={isLoading}>
                {isLoading ? (
                  <span style={{ color: "#fff", fontSize: "18px" }}>
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#fff" }}
                    />{" "}
                    Please Wait...{" "}
                  </span>
                ) : (
                  "Confirm"
                )}
              </Button>
            </Modal.Body>
          </>
        )}
        {paymentState === 3 && (
          <>
            <Modal.Header>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <Button type="button">
                  <img
                    src={require("../assets/img/close-modal.png").default}
                    alt=""
                    onClick={closeModal}
                  />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body className="text-center pb-5">
              <span>Make Payment 3</span>
              <div className="steps-main d-flex my-4">
                <div className="step-by mx-auto d-flex gap">
                  <div className="step active">
                    <span>1</span>
                  </div>
                  <div className="step active">
                    <span>2</span>
                  </div>
                  <div className="step active">
                    <span>3</span>
                  </div>
                </div>
              </div>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className="form">
                    <img
                      src={require("../assets/img/Success.png").default}
                      alt=""
                    />
                    <span className="w-100 d-block">
                      {resData.ResponseMsg}
                    </span>
                  </div>
                </Col>
              </Row>
              <Button className="mt-4" onClick={complete}>
                Complete
              </Button>
            </Modal.Body>
          </>
        )}
      </Modal>
    </span>
  );
}

export default PaymentModal
