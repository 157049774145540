import React, { useEffect, useState } from 'react'
import $ from "jquery"
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REDUX_ACTIONS } from '../../Redux/Actions/DataObjectAction';
import downArrorw from '../../assets/img/Union.png'
import { useForm } from "react-hook-form";
import Header from '../Header';
const Password = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } });
    }, []);

    const navigate = useNavigate();
    const { dataObject } = useSelector((state) => state);

    var user = JSON.parse(localStorage.getItem("user"));
    var uid = localStorage.getItem("userId");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submitForm = async (data) => {
        $("#loadSpin").removeAttr("hidden", "hidden");
        $(".navbar, .box-profile ").css("opacity", ".1");
        var md5 = require('md5');
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key == "password") {
                formData.append(key,md5(data[key]));
            } else if (key == "new_password"){
                formData.append(key, md5(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        });

        var token = localStorage.getItem("token");
        formData.append("user_id", user.user_id);

        const options = {
            method: "POST",
            headers: { token: token },
            data: formData,
            url: "/User/changePassword",
        };
        axios(options)
            .then((res) => {
                const { ResponseMsg, ResponseCode, Result } = res.data;
                if (ResponseCode === 1 && Result) {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    toast.success(ResponseMsg);
                } else {
                    $("#loadSpin").attr("hidden", "hidden");
                    $(".navbar, .box-profile ").css("opacity", "1");
                    toast.error(ResponseMsg);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <>

            <div className="profile-page">
                <div className="box-signup box-profile d-flex align-items-center justify-content-center">
                    <div className=" boxer-sign boxer-profile">

                        <form className='mt-3' onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">Password</label>
                                    <input
                                        type="text"
                                        {...register("password")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="**********"
                                    />
                                </div>
                                <div className="col-12 form-group mb-4">
                                    <label htmlFor=" ">New Password</label>
                                    <input
                                        type="password"
                                        {...register("new_password")}
                                        className="form-control border-0 shadow-none"
                                        placeholder="**********"
                                    />
                                </div>
                            </div>
                            <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
                                UPDATE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Password