
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Test = () => {
  return (
      <div>
          <Row>
              <Col sm={6}>
                  <Row>
                      <Col sm={12}>1.1</Col>
                      <Col sm={12}>1.2</Col>
                      <Col sm={12}>1.3</Col>
                      <Col sm={12}>1.4</Col>
                  </Row>
              </Col>
              <Col sm={6}>
                  <Row>
                      <Col sm={12}>2.1</Col>
                      <Col sm={12}>2.2</Col>
                      <Col sm={12}>2.3</Col>
                      <Col sm={12}>2.4</Col>
                  </Row>
              </Col>
          </Row>
    </div>
  )
}

export default Test


// import React, { useEffect, useState, Fragment } from "react";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { REDUX_ACTIONS } from "../Redux/Actions/DataObjectAction";
// import downArrorw from '../assets/img/Union.png'
// import { useNavigate } from "react-router-dom";
// import Header from "./Header";
// import $ from 'jquery';

// var md5 = require("md5");

// const Profile = () => {
//     const dispatch = useDispatch();
//     useEffect(() => {
//         dispatch({ type: REDUX_ACTIONS.TOGGLE, payload: { toggle: false } });
//     }, []);

//     const navigate = useNavigate();
//     const { dataObject } = useSelector((state) => state);
//     const [userName, setUserName] = useState("");
//     const [userEmail, setUserEmail] = useState("");
//     const [userGender, setUserGender] = useState("");
//     const [userProfile, setUserProfile] = useState('');

//     var user = JSON.parse(localStorage.getItem("user"));
//     var uid = localStorage.getItem("userId");
//     const UpdateProfile = async () => {
//         setUserName(user.name);
//         setUserEmail(user.email);
//         setUserGender(user.gender);
//         setUserProfile(user.profile);
//     }
//     useEffect(async () => {
//         if (uid == null) {
//             navigate('/')
//         } else {
//             await UpdateProfile();
//         }

//     }, []);


//     const submitForm = async (e) => {
//         $("#loadSpin").removeAttr("hidden", "hidden");
//         $(".navbar, .box-profile ").css("opacity", ".1");
//         let formData = new FormData();
//         var token = localStorage.getItem("token");
//         formData.append("user_id", user.user_id);
//         formData.append("email", userEmail);
//         formData.append("gender", userGender);
//         formData.append("name", userName);
//         formData.append("profile", userProfile);
//         // console.log(md5(password));


//         const options = {
//             method: "POST",
//             headers: { token: localStorage.getItem("token") },
//             data: formData,
//             url: "https://instasale.app/Instasale/Api/User/updateProfile",
//         };
//         axios(options)
//             .then((res) => {
//                 const { ResponseMsg, ResponseCode, Result } = res.data;
//                 if (ResponseCode === 1 && Result) {
//                     $("#loadSpin").attr("hidden", "hidden");
//                     $(".navbar, .box-profile ").css("opacity", "1");
//                     localStorage.setItem("user", JSON.stringify(res.data.data));
//                     toast.success(ResponseMsg);
//                 } else {
//                     $("#loadSpin").attr("hidden", "hidden");
//                     $(".navbar, .box-profile ").css("opacity", "1");
//                     toast.error(ResponseMsg);
//                 }
//             })
//             .catch((err) => {
//                 toast.error(err);
//             });
//     };
//     return (
//         <>
//             <Header />
//             <div
//                 id="loadSpin"
//                 className="spinner-border community-spinner"
//                 style={{ width: "3rem", height: "3rem" }}
//                 role="status"
//                 hidden
//             ></div>
//             <div className="profile-page">
//                 <div className="box-signup box-profile d-flex align-items-center justify-content-center">
//                     <div className=" boxer-sign boxer-profile">
//                         <div className="profile-setting">
//                             <div className="profile-img">
//                                 <img
//                                     src={
//                                         userProfile == "" || userProfile == null
//                                             ? require("../assets/img/ic_profile.png").default
//                                             : userProfile
//                                     }
//                                     alt=""
//                                 />
//                             </div>
//                         </div>
//                         <h1 className="mb-2 text-center">My Profile</h1>


//                         <form onSubmit={submitForm}>
//                             <div className="form-group mb-4">
//                                 <label htmlFor=" ">Username</label>
//                                 <input
//                                     type="text"
//                                     onChange={(e) => setUserName(e.target.value)}
//                                     value={userName}
//                                     className="form-control border-0 shadow-none"
//                                     id=""
//                                     placeholder="username"
//                                 />
//                             </div>

//                             <div className="form-group mb-4">
//                                 <label htmlFor=" ">Email address</label>
//                                 <input
//                                     type="email"
//                                     onChange={(e) => setUserEmail(e.target.value)}
//                                     readOnly="true"
//                                     value={userEmail}
//                                     className="form-control border-0 shadow-none"
//                                     id=""
//                                     placeholder="email"
//                                 />
//                             </div>

//                             <div className="form-group mb-4 gender">
//                                 <label htmlFor="">Gender</label>
//                                 <img src={downArrorw} alt="" />
//                                 <select
//                                     onChange={(e) => setUserGender(e.target.value)}
//                                     className="form-control border-0 shadow-none"
//                                     value={userGender}
//                                     placeholder="Select Gender"
//                                 >
//                                     <option value="" disabled>
//                                         Select Gender
//                                     </option>
//                                     <option name="male" id="" value="Male">
//                                         Male
//                                     </option>
//                                     <option name="female" id="" value="Female">
//                                         Female
//                                     </option>
//                                 </select>
//                             </div>

//                             <button className="btn text-center w-100 btn-sign text-white mt-2 mb-2">
//                                 UPDATE
//                             </button>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
// export default Profile;
