import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { BsApple } from 'react-icons/bs'
import { FaGooglePlay } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MainContainer from './MainContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  const { dataObject } = useSelector((state) => state);
  const [resOddData, setResOddData] = useState([]);
  const [resEvenData, setResEvenData] = useState([]);
  const [favoriteProduct, setfavoriteProduct] = useState();
  const [cartImg, setcartImg] = useState();
  const [cartTotalPrice, setcartTotalPrice] = useState();
  const [responseData, setResponseData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [homeData, setHomeData] = useState([])

  const [filterTagData, setFilterTagData] = useState({
    product_tag: [],
    price: []
  });

  // read more
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="read-text">
        {isReadMore ? text.slice(0, 250) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {!isReadMore  ? " show less" : text.length>250 &&" ...read more "}
        </span>
      </p>
    );
};

  let formData = new FormData();
  var uid = localStorage.getItem("userId");
  if (uid == null) {
    formData.append("user_id", "");
  } else {
    formData.append("user_id", uid);
  }

  useEffect(() => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".navbar, .offers-main").css("opacity", ".1");
    formData.append("search_name", (dataObject.searchData && dataObject.searchData.search_name) || "");

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/User/getHome",
      // url: "https://kmphasisinfotech.com/Instasale/Api/User/getHome",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");

          //popularOffer
          let arr1 = res.data.data.popular_offer;
          let arr2 = res.data.data.favorite_product;
          
          setHomeData(arr1.concat(arr2));
           //popularOffer filter(disabled)
          const categoryData = res.data.data.popular_offer.filter(
            (val) => val.min_quantity === location.state
          );
          if (categoryData.length > 0) {
            setHomeData(categoryData);
            setProductData(categoryData);
          } else {
            setHomeData(arr1.concat(arr2));
            setProductData(arr1.concat(arr2));
          }
          // setPopularOffer(res.data.data);
          setfavoriteProduct(res.data.data.favorite_product);
          
          // voucher deals
          const odd = [];
          const even = [];
          res.data.data.voucher_deals.map((data, i) => {
            if (i % 2 == 0) odd.push(data);
            else even.push(data);
          });
          setResOddData(odd);
          setResEvenData(even);
          //cart
          setcartImg(res.data.cart_outlet_image);
          setcartTotalPrice(res.data.cart_total);
        } else {
          $("#loadSpin").attr("hidden", "hidden");
          $(".navbar, .offers-main").css("opacity", "1");
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Filter change handler
  const onFilter = (minprice, maxprice) => {
    const category_price = productData.filter(
      (val) =>
        (val.normal_price >= minprice && val.normal_price <= maxprice) ||
        (maxprice == -1 && val.normal_price >= minprice)
    );

    setHomeData(category_price);
  };

  //Category Filter
  const cat_id = (category_id) => {
    const categoryData = productData.filter((val) => val.cat_id === category_id);
    setHomeData(categoryData);
  }
  //Seller outletId
  const seller_outletId = (seller_outlletId) => {
    const outletId = productData.filter((val) => val.outlet_id == seller_outlletId);

    setHomeData(outletId);
  }
  //TagFilter
  
  const handleTagFilter = (product_tags) => {

    const products_tags = productData.map((item) => item.product_tags);

    const product_tag = productData.filter((val) => {
      if (product_tags.length == 0) {
        return val;
      }
      else {
        var tagArr = (val.offer_tag).split(",");
        for (let m = 0; m < tagArr.length; m++) {
          const searchTag = (tagArr[m]).trim();
          if (product_tags.includes(searchTag)) {
            return val;
          }
        }
      }

    });
    setHomeData(product_tag);
  }

  // add to cart
  const addTocart = (data) => {
    console.log("product_id",data);
    if (!localStorage.getItem("token")) {
      toast.error("please login first");
      navigate("/login");
      return;
    }
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("price", data.normal_price);
    formData.append("outlet_id", data.outlet_id);
    formData.append("product_id", data.prod_id);

    const options = {
      method: "POST",
      headers: { token: localStorage.getItem("token") },
      data: formData,
      url: "/Order/addCart",
    };
    axios(options)
      .then((res) => {
        const { ResponseMsg, ResponseCode, Result } = res.data;
        if (ResponseCode === 1 && Result) {
          navigate("/cart");
        } else {
          toast.error(ResponseMsg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  var settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  return (
    <MainContainer
      onFilter={onFilter}
      seller_outletId={seller_outletId}
      cat_id={cat_id}
      handleTagFilter={handleTagFilter}>
      <div
        id="loadSpin"
        className="spinner-border community-spinner"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
        hidden>
      </div>
      <div
        className={`${
          !dataObject.toggle ? "home-margin" : "offers-main"
        } offers-main p-3`}
      >
        <Row className="m-0">
          <Col lg={9} md={12}>
            <div className="offers-left">
              <div className="offers-header text-center">
                <h1>
                  <span className="pink">INSTA</span>
                  <span className="orange">SALE -</span> instant offers, right where you are...
                </h1>
              </div>
              <div className="popular-offers">
                <div className="pupular-offers-header d-flex align-items-center">
                  <h4 className="mb-0 mt-1">POPULAROFFERS</h4>
                  <Button>
                    <img
                      src={require("../assets/img/catalog.png").default}
                      alt=""
                    />
                  </Button>
                </div>
                <Row className="mt-3">
                  <div className="d-flex flex-wrap rowper">
                    {homeData && homeData.length
                      ?homeData.map((val, index) => (
                          <div
                            key={index}
                            className="offers-box text-center"
                            
                          >
                            <div className="offers-images">
                              <img
                                src={val.images && val.images[0].offer_image}
                                onClick={() =>
                                navigate(`/productdetail/${val.product_id}`)
                              }
                                alt=""
                              />
                            </div>
                            <h5 className="mt-1">{val.title}</h5>
                          <h4 className="orange">
                              {val.normal_price}
                              {/* {val.product_id} */}
                            </h4>
                          </div>
                        ))
                      : <div className="no_data">
                          <h3>No Item Found</h3>
                        </div>}
                  </div>
                </Row>
              </div>
              <div className="vouchers py-2">
                <div className="pupular-offers-header d-flex align-items-center">
                  <h4 className="mb-0 mt-1">TRENDING DEALS {`&`} VOUCHERS</h4>
                  <Button>
                    <img
                      src={require("../assets/img/catalog.png").default}
                      alt=""
                    />
                  </Button>
                </div>
                <div className="vouchers-main mt-3">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <div className="left-vouchers">
                        {resOddData && resOddData.length > 0
                          ? resOddData.map((data, i) => {
                              return (
                                <Row className="d-flex" key={i}>
                                  <Col
                                    xxl={5}
                                    lg={12}
                                    sm={12}
                                    className="voucher-box h-100 mt-2"
                                    onClick={() => navigate(`/productdetail/${data.prod_id}`)}
                                  >
                                    <img
                                      className="v-img w-100"
                                      src={
                                        data.images && data.images[0].v_image
                                      }
                                      alt=""
                                    />
                                  </Col>
                                  <Col
                                    xxl={7}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="voucher-text mt-2"
                                  >
                                    <div className="voucher_detail">
                                      <h5>{data.v_title}</h5>
                                      {data.long_desc == ''
                                        ? <p style={{ opacity: '0' }}>No Description</p>
                                        : <ReadMore>{data.long_desc}</ReadMore>
                                      }
                                      
                                    </div>
                                    <div className="d-flex align-items-center share-btn">
                                      {/* <div className="mc-icon"> */}
                                      <img
                                        src={data.outlet_logo}
                                        alt=""
                                        style={{ height: "42px" }}
                                      />
                                      {/* </div> */}
                                      <div className="voucher-btn">
                                        <Button className="share" type="button" >
                                          Share
                                        </Button>
                                        <Button className="get" type="button" onClick={() => addTocart(data)}>
                                          Buy
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })
                          : <div className="no_data">
                              <h3>No Item Found</h3>
                            </div>
                        }
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      {resEvenData && resEvenData.length > 0
                        ? resEvenData.map((data, i) => {
                            return (
                              <Row className="d-flex even_data " key={i}>
                                <Col
                                  xxl={5}
                                  lg={12}
                                  sm={12}
                                  className="voucher-box h-100 mt-2"
                                  onClick={() => navigate(`/productdetail/${data.prod_id}`)}
                                >
                                  <img
                                    className="v-img w-100"
                                    src={
                                      data.images == null || data.images == ""
                                        ? require("../assets/img/mini-logo.png").default
                                        : data.images && data.images[0].v_image
                                    }
                                    alt=""
                                  />
                                </Col>
                                <Col
                                  xxl={7}
                                  lg={12}
                                  sm={12}
                                  className="voucher-text mt-2"
                                >
                                  <div className="voucher_detail">
                                    <h5>{data.v_title}</h5>
                                    {data.long_desc == ''
                                        ? <p style={{ opacity: '0' }}>No Description</p>
                                        : <ReadMore>{data.long_desc}</ReadMore> 
                                    }
                                  </div>
                                  
                                  <div className="d-flex align-items-center share-btn">
                                    {/* <div className="mc-icon"> */}
                                    <img
                                      src={data.outlet_logo}
                                      alt=""
                                      style={{ height: "42px" }}
                                    />
                                    {/* </div> */}
                                    <div className="voucher-btn">
                                      <Button className="share" type="button">
                                        Share
                                      </Button>
                                      <Button className="get" type="button" onClick={() => addTocart(data)}>
                                        Buy
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })
                        : <div className="no_data">
                            <h3>No Item Found</h3>
                          </div> 
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="offers-right">
              <div className="your_cart" onClick={() => navigate("/cart")}>
                <div className=" d-flex justify-content-between align-items-center">
                  <h4>Your Cart</h4>
                  <img
                    className="cart_img"
                    src={
                      cartImg == "" || cartImg == null
                        ? require("../assets/img/InstaSale.png").default
                        : cartImg
                    }
                    alt=""
                  />
                </div>instasale 
                <div className="cart-per d-flex justify-content-between align-items-center">
                  <img
                    src={require("../assets/img/your_cart.png").default}
                    onClick={() => navigate("/cart")}
                    alt=""
                  />
                  <span>
                    <p>KR.</p>{cartTotalPrice == "" || cartTotalPrice == null
                      ? "0"
                      : cartTotalPrice}
                  </span>
                </div>
              </div>
              {/* <div className="cash mt-3 text-center">
                <h4>Cash Referals</h4>
                <span>KR.33.75</span>
                <h4>
                  Points <span>3594</span>
                </h4>
              </div> */}
              <div className="cash mt-3 text-center">
                <h4>Favourite Shops</h4>
                
                <div className="fav-img d-flex justify-content-center ">
                  {favoriteProduct == null || favoriteProduct == ""
                    ? ""
                    :favoriteProduct && favoriteProduct.map((data, i) => {
                      return data.images.map((img) => (
                        <img
                        className="mt-2 "
                        key={i}
                        src={img.img}
                        alt=""
                      />
                      ))
                    })}
                  {/* <Slider {...settings}>
                    <>
                      {favoriteProduct &&
                        favoriteProduct.map((data, i) => (
                          <img
                            className="mt-2"
                            key={i}
                            src={data.images[i].img}
                            alt=""
                          />
                        ))}
                    </>
                  </Slider> */}
                  
                </div>
              </div>
              <div className="social mt-3 text-center">
                <img
                  src={require("../assets/img/mini-logo.png").default}
                  alt=""
                />
                <h2 className="mt-3">DOWNLOAD </h2>
                <h2>INSTASALE APP</h2>
                <img className='w-100' src={require("../assets/img/phone.png").default} alt="" />
                <div className="social-icon d-flex justify-content-center">
                  <Button type="button">
                    <BsApple style={{ color: "white" }} />
                  </Button>
                  <Button type="button">
                    <FaGooglePlay style={{ color: "white" }} />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MainContainer>
  );
}
