import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offers from './components/Offers'
import Login from './components/Login';
import Map from './components/Map';
import Home from './components/Home';
import Cart from './components/Cart';
import { useNavigate } from "react-router-dom";
import './App.css'

import Signup from './components/Signup';
import axios from 'axios';
import ProductsDetail from './components/ProductDetail'
import Profile from './components/Profile';
import ForgotPassword from './components/Forgot_Password';
import Outletseller from './components/Deal'
import Deal from './components/Deal';
import BonusReword from './components/BonusReword';
import Test from './components/Test';

axios.defaults.baseURL = "https://instasale.app/Instasale/Api";

axios.defaults.headers.post['key'] = "42e4fb9cdf992102e67d3098848b7c28";

const App = () => {

  const navigate = useNavigate();
  localStorage.setItem("lat", 21.234305738172736)
  localStorage.setItem("long", 72.81260258113572)
  if (!localStorage.getItem("country"))
    localStorage.setItem("country", JSON.stringify({ value: 'US', label: 'United States' }))

  var isLogin = localStorage.getItem("token");
  return (
    <>
      <ToastContainer autoClose={3500} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/map" element={<Map />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/productdetail/:id" element={<ProductsDetail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/deal" element={<Deal />} />
        <Route path="/rewards" element={<BonusReword />} />
        <Route path="/test" element={<Test />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App
